import { Button } from '@mui/material'
import { blue } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import React from 'react'
import { useTranslation } from 'react-i18next'

const GoogleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#4285f4'),
  backgroundColor: '#4285f4',
  '&:hover': {
    backgroundColor: blue[700],
  },
}))

export default ({ onClick, text }) => {
  const { t } = useTranslation()

  return (
    <GoogleButton
      variant="contained"
      onClick={onClick}
      startIcon={
        <img
          src="/google.svg"
          style={{
            width: '12px',
          }}
          alt={text}
        />
      }
    >
      {text}
    </GoogleButton>
  )
}

/* eslint-disable react/jsx-filename-extension */
import { CssBaseline } from '@mui/material'
import App from 'App'
import CustomThemeProvider from 'components/CustomThemeProvider'
import Progress from 'components/Progress'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'

ReactDOM.render(
  <React.StrictMode>
    <CustomThemeProvider>
      <CssBaseline />

      <Suspense fallback={<Progress />}>
        <App />
      </Suspense>
    </CustomThemeProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

import { useContext } from 'react'
import { CustomThemeContext } from './CustomThemeProvider'

function useCustomTheme() {
  const { theme, setTheme } = useContext(CustomThemeContext)

  return { theme, setTheme }
}

export default useCustomTheme

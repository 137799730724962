import { Edit, Report, ShieldOutlined } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { getUserCountry } from 'api/firebase'
import { format, parse } from 'date-fns'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'
import BikeCardLine from './BikeCardLine'

export default ({ vehicle, onAdd, onInsuranceEdit, onGetConnect }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const [theftReportUrl, setTheftReportUrl] = useState('')

  const { data: country } = useQuery(['user', 'country'], getUserCountry, {
    onError: (e) => showError(snackbar, e, t),
  })

  useEffect(() => {
    if (country) {
      setTheftReportUrl(country.theftReportUrl)
    }
  }, [country])

  return (
    <Card>
      <CardHeader
        avatar={<ShieldOutlined />}
        title={<Typography variant="h6">{t('Insurance')}</Typography>}
      />
      <CardContent>
        <Grid container>
          <Grid container spacing={4}>
            {!(
              get(vehicle, 'insuranceSubscription.status', undefined) ===
              'active'
            ) && (
              <Grid
                item
                container
                direction="column"
                spacing={1}
                xs={12}
                md={6}
              >
                <Grid item>
                  <BikeCardLine
                    label={t('Third party insurance')}
                    value={!!vehicle.thirdPartyInsurance}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Insurance company')}
                    value={get(
                      vehicle,
                      'thirdPartyInsurance.insuranceCompany',
                      undefined
                    )}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Contract number')}
                    value={get(
                      vehicle,
                      'thirdPartyInsurance.contractNumber',
                      undefined
                    )}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Start date')}
                    value={format(
                      parse(
                        get(
                          vehicle,
                          'thirdPartyInsurance.contractStartDate',
                          format(new Date(), 'yyyyMMdd')
                        ),
                        'yyyyMMdd',
                        new Date()
                      ),
                      'dd LLL yyyy',
                      new Date()
                    )}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Emergency phone number')}
                    value={get(
                      vehicle,
                      'thirdPartyInsurance.emergencyPhoneNumber',
                      undefined
                    )}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Contract')}
                    value={get(
                      vehicle,
                      'thirdPartyInsurance.contract.url',
                      undefined
                    )}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction={isSmallScreen ? 'column' : 'row'}
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={4}
        >
          {theftReportUrl && (
            <Grid item>
              <Button
                color="inherit"
                startIcon={<Report />}
                href={theftReportUrl}
                target="_blank"
              >
                {t('Report a theft')}
              </Button>
            </Grid>
          )}
          {!(
            get(vehicle, 'insuranceSubscription.status', undefined) === 'active'
          ) && (
            <Button
              color="inherit"
              startIcon={<Edit />}
              onClick={onInsuranceEdit}
            >
              {t('Edit')}
            </Button>
          )}
        </Grid>
      </CardActions>
    </Card>
  )
}

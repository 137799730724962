import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import SaveVehicleForm from 'features/common/SaveVehicleForm'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default ({ open, onClose, onSaveVehicle, vehicle }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {vehicle ? t('Edit bike') : t('Add bike')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <SaveVehicleForm
          onSaveVehicle={(data) => {
            onClose()
            onSaveVehicle(data)
          }}
          vehicle={vehicle}
        />
      </DialogContent>
    </Dialog>
  )
}

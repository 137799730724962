import polyline from '@mapbox/polyline'
import { firebaseDateToJs } from 'api/firebase'
import get from 'lodash.get'
import metersToMiles from 'utils/metersToMiles'

const download = function (content, fileName, mimeType) {
  const a = document.createElement('a')
  mimeType = mimeType || 'application/octet-stream'

  if (navigator.msSaveBlob) {
    // IE10
    navigator.msSaveBlob(
      new Blob([content], {
        type: mimeType,
      }),
      fileName
    )
  } else if (URL && 'download' in a) {
    // html5 A[download]
    a.href = URL.createObjectURL(
      new Blob([content], {
        type: mimeType,
      })
    )
    a.setAttribute('download', fileName)
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
  } else {
    location.href = `data:application/octet-stream,${encodeURIComponent(
      content
    )}` // only this mime type is supported
  }
}

export const generateHistory = (trackerEvents, vehicle) => {
  const headers = ['Date', 'Latitude', 'Longitude']
  let csv = `${headers.join(';')}\n`
  trackerEvents.forEach((trackerEvent) => {
    const data = {
      date: new Date(trackerEvent.timestamp * 1000).toLocaleString(),
      latitude: trackerEvent.longitude.toLocaleString({
        maximumFractionDigits: 4,
        useGrouping: false,
      }),
      longitude: trackerEvent.longitude.toLocaleString({
        maximumFractionDigits: 4,
        useGrouping: false,
      }),
    }

    csv += `${data.date};${data.latitude};${data.longitude}\n`
  })

  download(csv, `${vehicle.name}-history.csv`, 'text/csv;encoding:utf-8')
}

export const generateExpenseReport = (t, user, trips, documentId) => {
  const distanceUnit = get(user, 'distanceUnit', 'kilometers')
  const price = get(user, 'expenseReportConfiguration.price', 0.35)
  const currency = get(user, 'currency', 'EUR')

  const headers = [
    'Date',
    'Time',
    'Start GPS position',
    'End GPS position',
    'Price',
    'Distance',
    'Total',
  ]

  let csv = `${headers.join(';')}\n`

  trips.forEach((trip) => {
    const { endTime } = trip.summary
    const distanceInMeters = trip.summary.distance
    const distance =
      distanceUnit === 'miles'
        ? metersToMiles(distanceInMeters)
        : distanceInMeters / 1000

    let startCoordinate = null
    let endCoordinate = null

    if (trip.summary.polyline) {
      const coordinates = polyline.decode(trip.summary.polyline)

      if (coordinates.length >= 2) {
        startCoordinate = {
          latitude: coordinates[0][0],
          longitude: coordinates[0][1],
        }

        endCoordinate = {
          latitude: coordinates[coordinates.length - 1][0],
          longitude: coordinates[coordinates.length - 1][1],
        }
      }
    }

    const data = {
      date: firebaseDateToJs(endTime).toLocaleDateString(),
      time: firebaseDateToJs(endTime).toLocaleTimeString(),
      coordinatesStart: startCoordinate
        ? `${startCoordinate.latitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}, ${startCoordinate.longitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}`
        : t('No GPS data'),
      coordinatesEnd: endCoordinate
        ? `${endCoordinate.latitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}, ${endCoordinate.longitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}`
        : t('No GPS data'),
      price,
      distance: `${distance.toLocaleString({
        minimumIntegerDigits: 1,
        maximumFractionDigits: 2,
        useGrouping: false,
      })}`,
      total: (distance * price).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        useGrouping: false,
      }),
    }

    csv += `${data.date};${data.time};${data.coordinatesStart};${data.coordinatesEnd};${data.price};${data.distance};${data.total}\n`
  })

  download(csv, `report-${documentId}.csv`, 'text/csv;encoding:utf-8')
}

// eslint-disable-next-line import/prefer-default-export
export const generateStatsReport = (
  t,
  vehicle,
  trips,
  documentId,
  distanceUnit = 'kilometers'
) => {
  const headers = [
    'Date',
    `Distance (${distanceUnit})`,
    'Duration (min)',
    'Elevation gain (meters)',
    'Saved CO2 (g)',
    'Coordinates Start',
    'Coordinates End',
  ]

  let csv = `${headers.join(';')}\n`

  trips.forEach((trip) => {
    const { endTime, startCoordinate, endCoordinate } = trip.summary
    const distanceInMeters = trip.summary.distance
    const distance =
      distanceUnit === 'miles'
        ? metersToMiles(distanceInMeters)
        : distanceInMeters / 1000

    const data = {
      distance: `${distance.toLocaleString({
        minimumIntegerDigits: 1,
        maximumFractionDigits: 2,
        useGrouping: false,
      })}`,
      date: firebaseDateToJs(endTime).toLocaleString(),
      duration: `${trip.summary.duration * 60}`,
      elevationGain: Math.round(trip.summary.elevationGain),
      savedCO2: trip.summary.savedCO2,
      coordinatesStart: `${startCoordinate.latitude.toLocaleString({
        maximumFractionDigits: 4,
        useGrouping: false,
      })}, ${startCoordinate.longitude.toLocaleString({
        maximumFractionDigits: 4,
        useGrouping: false,
      })}`,
      coordinatesEnd: `${endCoordinate.latitude.toLocaleString({
        maximumFractionDigits: 4,
        useGrouping: false,
      })}, ${endCoordinate.longitude.toLocaleString({
        maximumFractionDigits: 4,
        useGrouping: false,
      })}`,
    }

    csv += `${data.date};${data.distance};${data.duration};${data.elevationGain};${data.savedCO2};${data.coordinatesStart};${data.coordinatesEnd}\n`
  })

  download(
    csv,
    `${vehicle.name}-stats-${documentId}.csv`,
    'text/csv;encoding:utf-8'
  )
}

/* eslint-disable react/jsx-filename-extension */
import { Button, Grow } from '@mui/material'
import { SnackbarProvider } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { BrowserRouter } from 'react-router-dom'
import Routes from 'routes/Routes'

require('./i18n')

const queryClient = new QueryClient()

export default () => {
  const { t } = useTranslation()
  // add action to all snackbars
  const notistackRef = React.createRef()
  const onClickDismiss = (key) => () => {
    notistackRef.current.closeSnackbar(key)
  }

  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <Button onClick={onClickDismiss(key)} color="inherit">
          {t('Dismiss')}
        </Button>
      )}
      preventDuplicate
      maxSnack={3}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      TransitionComponent={Grow}
    >
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
          <Routes />
        </BrowserRouter>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </SnackbarProvider>
  )
}

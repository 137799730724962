import { DownloadOutlined } from '@mui/icons-material'
import { Button, Switch } from '@mui/material'
import { Box } from '@mui/system'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import { firebaseDateToJs } from 'api/firebase'
import { generateExpenseReport as generateExpenseReportCsv } from 'features/document/csvGeneration'
import { generateExpenseReport as generateExpenseReportPdf } from 'features/document/pdfGeneration'
import {
  createMRTColumnHelper,
  MaterialReactTable,
  useMaterialReactTable,
} from 'material-react-table'
import React from 'react'
import { useTranslation } from 'react-i18next'
import metersToMiles from 'utils/metersToMiles'

export default ({
  user,
  trips,
  onTripUpdate,
  yearMonths,
  yearMonth,
  onDateChange,
}) => {
  const { t } = useTranslation()

  const columnHelper = createMRTColumnHelper()

  const durationWithUnit = (duration) => {
    const totalDurationInMin = duration / 60
    return totalDurationInMin > 60
      ? `${Math.floor(totalDurationInMin / 60)} hours`
      : `${Math.floor(totalDurationInMin)} min`
  }

  const distanceWithUnit = (distance) => {
    switch (user.distanceUnit) {
      case 'miles':
        return `${Math.floor(metersToMiles(distance))} ${t('miles')}`
      default:
        return `${Math.floor(distance / 1000)} ${t('km')}`
    }
  }

  const columns = [
    columnHelper.accessor(
      (trip) => {
        const date = firebaseDateToJs(trip.summary.startTime)
        return `${date.toLocaleDateString()}`
      },
      {
        header: t('Date'),
      }
    ),
    columnHelper.accessor(
      (trip) => {
        const date = firebaseDateToJs(trip.summary.startTime)
        return `${date.toLocaleTimeString()}`
      },
      {
        header: t('Time'),
      }
    ),
    columnHelper.accessor((trip) => durationWithUnit(trip.summary.duration), {
      header: t('Duration'),
    }),
    columnHelper.accessor((trip) => distanceWithUnit(trip.summary.distance), {
      header: t('Distance'),
    }),
    columnHelper.accessor('isProfessional', {
      header: 'Professional',
      Cell: ({ renderedCellValue, row }) => (
        <Switch
          checked={renderedCellValue || false}
          onClick={(e) =>
            onTripUpdate({ ...row.original, isProfessional: e.target.checked })
          }
        />
      ),
    }),
    columnHelper.accessor(
      (trip) =>
        `${trip.summary.expensePrice.toLocaleString('en-US', {
          minimumIntegerDigits: 1,
          maximumFractionDigits: 2,
          useGrouping: false,
        })} ${trip.summary.expensePriceCurrency || user.currency}`,
      {
        header: 'Price',
        muiTableHeadCellProps: {
          align: 'right',
        },
        muiTableBodyCellProps: {
          align: 'right',
        },
      }
    ),
  ]

  const date = new Date()
  date.setFullYear(yearMonth.substring(0, 4))
  date.setMonth(yearMonth.substring(4, 6) - 1)

  const table = useMaterialReactTable({
    columns,
    data: trips.sort((a, b) => b.summary.startTime - a.summary.startTime),
    enableFullScreenToggle: false,
    enableDensityToggle: false,
    muiBottomToolbarProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTopToolbarProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTableBodyCellProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    muiTableProps: {
      sx: {
        backgroundColor: 'background.paper',
      },
    },
    renderTopToolbarCustomActions: ({ table }) => (
      <Box
        sx={{
          display: 'flex',
          gap: '16px',
          padding: '8px',
          flexWrap: 'wrap',
        }}
      >
        <LocalizationProvider dateAdapter={AdapterDateFns}>
          <DatePicker
            label={t('Month and year')}
            views={['month', 'year']}
            value={date}
            onChange={(value) => onDateChange(value)}
          />
        </LocalizationProvider>
        <Button
          // export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() =>
            generateExpenseReportCsv(
              t,
              user,
              trips.filter((t) => t.isProfessional),
              yearMonth
            )
          }
          variant="outlined"
          color="secondary"
          startIcon={<DownloadOutlined />}
        >
          {t('Export CSV')}
        </Button>
        <Button
          // export all data that is currently in the table (ignore pagination, sorting, filtering, etc.)
          onClick={() =>
            generateExpenseReportPdf(
              t,
              user,
              trips.filter((t) => t.isProfessional),
              yearMonth
            )
          }
          variant="outlined"
          color="secondary"
          startIcon={<DownloadOutlined />}
        >
          {t('Export PDF')}
        </Button>
      </Box>
    ),
  })

  return <MaterialReactTable table={table} />
}

import { Container, Grid } from '@mui/material'
import {
  getListOfYearMonthWithTrips,
  getMe,
  getTripsByYearMonth,
  updateTrip,
} from 'api/firebase'
import NavigationMenu from 'features/menu/NavigationMenu'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { showError, showInfoMessage } from 'utils/notifier'
import ExpenseReportsTable from './ExpenseReportTable'

export default ({ match }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const dateToYearMonth = (date) => {
    const month = date.getMonth()
    const monthHuman = month === 11 ? 1 : month + 1
    const monthString = monthHuman < 10 ? `0${monthHuman}` : `${monthHuman}`
    return `${date.getFullYear()}${monthString}`
  }

  const [yearMonth, setYearMonth] = useState(
    get(match, 'params.yearMonth', dateToYearMonth(new Date()))
  )

  const { data: trips, isLoading: tripsIsLoading } = useQuery(
    ['trips', yearMonth],
    () => getTripsByYearMonth(yearMonth),
    {
      placeholderData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { data: user, isLoading: userIsLoading } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const { data: yearMonths, isLoading: yearMonthsIsLoading } = useQuery(
    ['yearMonths'],
    () => getListOfYearMonthWithTrips(),
    {
      initialData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: saveTrip, isLoading: updateTripIsLoading } = useMutation(
    (trip) => updateTrip(trip),
    {
      onSuccess: (data) => {
        queryClient.invalidateQueries(['trips'])
        return showInfoMessage(snackbar, t('Trip updated'))
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  return (
    <NavigationMenu
      title={t('Report')}
      progress={
        userIsLoading ||
        updateTripIsLoading ||
        tripsIsLoading ||
        yearMonthsIsLoading
      }
    >
      <Container maxWidth="lg">
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="stretch"
          mt={4}
          mb={4}
        >
          <Grid item>
            {user && trips && (
              <ExpenseReportsTable
                user={user}
                trips={trips}
                onTripUpdate={saveTrip}
                yearMonths={yearMonths}
                yearMonth={yearMonth}
                onDateChange={(date) => setYearMonth(dateToYearMonth(date))}
              />
            )}
          </Grid>
        </Grid>
      </Container>
    </NavigationMenu>
  )
}

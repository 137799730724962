import { Help } from '@mui/icons-material'
import {
  Avatar,
  Button,
  Container,
  Grid,
  TextField,
  Typography,
} from '@mui/material'
import { passwordReset } from 'api/firebase'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router-dom'
import { showError, showInfoMessage } from 'utils/notifier'

export default () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const history = useHistory()

  const [email, setEmail] = useState('')

  const handleSubmit = async (event) => {
    if (event) {
      event.preventDefault()
    }

    try {
      await passwordReset(email)
      showInfoMessage(
        snackbar,
        t('An email with reset instructions has been sent')
      )
      history.push('/')
    } catch (e) {
      showError(snackbar, e, t)
    }
  }

  const isInvalid = email === ''

  return (
    <Container>
      <Grid
        container
        direction="column"
        justify="center"
        alignItems="center"
        spacing={4}
        mt={4}
      >
        <Grid item>
          <Avatar>
            <Help />
          </Avatar>
        </Grid>
        <Grid item>
          <Typography variant="h6">{t(`Reset Password`)}</Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1" align="center">
            {t(
              `Enter your email and receive instruction on how to reset your password`
            )}
          </Typography>
        </Grid>
        <Grid
          container
          direction="column"
          justify="flex-start"
          alignItems="center"
          spacing={2}
          component="form"
          onSubmit={handleSubmit}
        >
          <Grid item>
            <TextField
              variant="outlined"
              margin="normal"
              required
              fullWidth
              label={t('Email address')}
              autoComplete="email"
              autoFocus
              onChange={(e) => setEmail(e.target.value)}
            />
          </Grid>
          <Grid item>
            <Button
              disabled={isInvalid}
              type="submit"
              variant="contained"
              color="primary"
            >
              {t(`Resend an Email`)}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  )
}

import { AddModerator, Close, UploadFile } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Stack,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import documentTypes from 'api/documentTypes'
import { getInsuranceCompanies, getUserCountry } from 'api/firebase'
import FormTextField from 'components/FormTextField'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import { format, parse } from 'date-fns'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'

export default ({
  open,
  onClose,
  onSave,
  onAdd,
  onDelete,
  insurance,
  vehicle,
}) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const [contract, setContract] = useState()

  const { data: country } = useQuery(['user', 'country'], getUserCountry, {
    onError: (e) => showError(snackbar, e, t),
  })

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'all' })

  const { data: insuranceCompanies, isLoading } = useQuery(
    'insuranceCompanies',
    getInsuranceCompanies,
    {
      initialData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const handleDocumentSet = async ({ target }) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onloadend = (e) => {
      setContract({
        ...documentTypes.thirdPartyInsurance.contract,
        data: e.target.result,
        extension: target.files[0].name.split('.').pop().toLowerCase(),
        name: target.files[0].name,
      })
    }
  }

  const isFormValid = (isValid && isDirty) || contract

  const onSubmit = async ({
    insuranceCompany,
    contractNumber,
    emergencyPhoneNumber,
    contractStartDate,
  }) => {
    onClose()
    onSave(
      {
        insuranceCompany,
        contractNumber,
        emergencyPhoneNumber,
        contractStartDate: format(contractStartDate, 'yyyyMMdd'),
      },
      contract
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {insurance ? t('Edit insurance') : t('Add insurance')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={2}
        >
          <Grid item xs={12}>
            <Controller
              name="insuranceCompany"
              control={control}
              defaultValue={get(insurance, 'insuranceCompany', '')}
              rules={{
                required: t('Required'),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <VirtualizedAutocomplete
                  options={insuranceCompanies}
                  label={t('Insurance company')}
                  required
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : null}
                  error={!!error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              required
              name="contractNumber"
              control={control}
              defaultValue={get(insurance, 'contractNumber', '')}
              label={t('Contract number')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="contractStartDate"
              control={control}
              defaultValue={parse(
                get(
                  insurance,
                  'contractStartDate',
                  format(new Date(), 'yyyyMMdd')
                ),
                'yyyyMMdd',
                new Date()
              )}
              rules={{
                required: t('Required'),
              }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label={t('Start date')}
                    value={value}
                    onChange={onChange}
                    renderInput={(props) => (
                      <TextField required fullWidth {...props} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              required
              name="emergencyPhoneNumber"
              control={control}
              defaultValue={get(insurance, 'emergencyPhoneNumber', '')}
              label={t('Emergency phone number')}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Button
              color="inherit"
              startIcon={<UploadFile />}
              component="label"
            >
              <input type="file" hidden onChange={handleDocumentSet} />
              {contract ? contract.name : t('Contract')}
            </Button>
          </Grid>
          <Grid item>
            <Stack spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading || !isFormValid}
              >
                {t('Save')}
              </Button>
              {vehicle.hasTrackerSubscription && country && country.insurance && (
                <Button
                  startIcon={<AddModerator />}
                  variant="contained"
                  onClick={onAdd}
                  color="secondary"
                >
                  {t('No insurance ?')}
                </Button>
              )}
              <Button
                fullWidth
                hidden={!!insurance}
                onClick={() => {
                  onClose()
                  onDelete()
                }}
                color="error"
              >
                {t('Delete')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

import MenuIcon from '@mui/icons-material/Menu'
import {
  AppBar,
  Divider,
  Drawer,
  Grid,
  IconButton,
  LinearProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
} from '@mui/material'
import { Box } from '@mui/system'
import React from 'react'
import { Link as RouterLink, useLocation } from 'react-router-dom'

const drawerWidthMobile = 260
const drawerWidthDesktop = 60

const CollisionLink = (path) =>
  React.forwardRef((props, ref) => (
    <RouterLink innerRef={ref} to={path} {...props} />
  ))

const NavigationDrawer = ({
  menu,
  logo,
  title,
  toolbarChildren,
  children,
  progress = false,
}) => {
  const location = useLocation()

  const isRouteActive = (routeName) => {
    return location.pathname.indexOf(routeName) > -1
  }

  const [mobileOpen, setMobileOpen] = React.useState(false)

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen)
  }

  const renderMobileList = () => {
    return menu.map((list, index) => (
      <Box key={index}>
        <List
          sx={{
            '.MuiListItemIcon-root': {
              color: 'white',
            },
            // selected and (selected + hover) states
            '&& .Mui-selected, && .Mui-selected:hover': {
              bgcolor: 'primary.main',
              '&, & .MuiListItemIcon-root': {
                color: 'white',
              },
            },
            // hover states
            '& .MuiListItemButton-root:hover': {
              bgcolor: 'primary.main',
              '&, & .MuiListItemIcon-root': {
                color: 'white',
              },
            },
          }}
        >
          {list.items.map((item) =>
            item.path.startsWith('http') ? (
              <ListItem
                button
                key={item.title}
                component="a"
                href={item.path}
                target="blank"
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.title} sx={{ color: 'white' }} />
              </ListItem>
            ) : (
              <ListItem
                button
                key={item.title}
                selected={isRouteActive(item.path)}
                component={CollisionLink(item.path)}
              >
                <ListItemIcon>
                  <item.icon />
                </ListItemIcon>
                <ListItemText primary={item.title} sx={{ color: 'white' }} />
              </ListItem>
            )
          )}
        </List>
        {menu.length - 1 !== index && <Divider />}
      </Box>
    ))
  }

  const renderDesktopList = () => {
    const flattenedList = menu.flatMap((list) => list.items)
    return (
      <List
        sx={{
          '.MuiListItemIcon-root': {
            color: 'white',
          },
          // selected and (selected + hover) states
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'primary.main',
            '&, & .MuiListItemIcon-root': {
              color: 'white',
            },
          },
          // hover states
          '& .MuiListItemButton-root:hover': {
            bgcolor: 'primary.main',
            '&, & .MuiListItemIcon-root': {
              color: 'white',
            },
          },
        }}
      >
        {flattenedList.map((item) =>
          item.path.startsWith('http') ? (
            <ListItem
              sx={{
                pt: 2,
                pb: 2,
              }}
              button
              key={item.title}
              component="a"
              href={item.path}
              target="blank"
            >
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
            </ListItem>
          ) : (
            <ListItem
              sx={{
                pt: 2,
                pb: 2,
              }}
              button
              key={item.title}
              selected={isRouteActive(item.path)}
              component={CollisionLink(item.path)}
            >
              <ListItemIcon>
                <item.icon />
              </ListItemIcon>
            </ListItem>
          )
        )}
      </List>
    )
  }

  const drawerMobile = (
    <Grid container direction="column">
      <Grid item alignSelf="center" m={4} p={2}>
        <img src={logo} alt="" style={{ width: '100px' }} />
      </Grid>
      <Grid item>{renderMobileList()}</Grid>
    </Grid>
  )

  const drawerDesktop = (
    <Grid container direction="column" style={{ overflowX: 'hidden' }}>
      <Grid item ml={1.5} mt={1.9}>
        <img src="/logo.png" alt="" style={{ width: '34px' }} />
      </Grid>
      <Grid item>{renderDesktopList()}</Grid>
    </Grid>
  )

  const container =
    window !== undefined ? () => window.document.body : undefined

  return (
    <Box sx={{ display: 'flex' }}>
      <AppBar
        position="fixed"
        sx={{
          width: { sm: `calc(100% - ${drawerWidthDesktop}px)` },
          ml: { sm: `${drawerWidthDesktop}px` },
          backgroundColor: 'secondary.main',
          '& .MuiTypography-root': {
            color: 'white',
          },
        }}
      >
        <Toolbar>
          <IconButton
            edge="start"
            onClick={handleDrawerToggle}
            sx={{ mr: 2, display: { sm: 'none' }, color: 'white' }}
          >
            <MenuIcon />
          </IconButton>
          <Typography
            color="white"
            variant="h6"
            noWrap
            component="div"
            sx={{ flexGrow: 1 }}
          >
            {title}
          </Typography>
          {toolbarChildren}
        </Toolbar>
        {progress && <LinearProgress />}
      </AppBar>
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidthDesktop }, flexShrink: { sm: 0 } }}
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          elevation={0}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidthMobile,
              backgroundColor: 'secondary.main',
              '& .MuiTypography-root': {
                color: 'white',
              },
            },
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
        >
          {drawerMobile}
        </Drawer>
        <Drawer
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': {
              boxSizing: 'border-box',
              width: drawerWidthDesktop,
              backgroundColor: 'secondary.main',
              '& .MuiTypography-root': {
                color: 'white',
              },
            },
          }}
          variant="permanent"
          open
        >
          {drawerDesktop}
        </Drawer>
      </Box>
      <Box component="main" sx={{ flexGrow: 1 }}>
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

export default NavigationDrawer

import { CircularProgress, Container, Grid, Typography } from '@mui/material'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'

export default () => {
  const history = useHistory()
  const { t } = useTranslation()

  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace('/')
    }, 4000)
    return () => clearTimeout(timer)
  })

  return (
    <Container>
      <Grid
        container
        style={{ minHeight: '90vh' }}
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
      >
        <Grid item>
          <CircularProgress />
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {t('Thank you for your purchase, redirecting')}
          </Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

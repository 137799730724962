import Products from 'features/products/Products'
import React from 'react'

const CountryForm = ({ onSave }) => {
  const handleOnSubmit = async (price, selectedCountry) => {
    onSave(
      {
        countryId: selectedCountry.countryCode,
        distanceUnit:
          selectedCountry.measurementSystem === 'imperial'
            ? 'miles'
            : 'kilometers',
        temperatureUnit: selectedCountry.temperatureUnit,
        currency: selectedCountry.currencyCode.toLowerCase(),
      },
      price
    )
  }

  return <Products onSubmit={handleOnSubmit} />
}
export default CountryForm

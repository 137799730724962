import { Container } from '@mui/material'
import useCustomTheme from 'components/useCustomTheme'
import React from 'react'
import Products from './Products'

export default () => {
  const { setTheme } = useCustomTheme()
  setTheme('light')

  return (
    <Container maxWidth="md">
      <Products onSubmit={() => window.open('/sign-up')} />
    </Container>
  )
}

import queryString from 'query-string'
import { useEffect } from 'react'
import { useLocation } from 'react-router'

const RedirectPage = () => {
  const location = useLocation()

  const { uri } = queryString.parse(location.search)

  useEffect(() => {
    window.location.href = uri
  }, [uri])

  return null
}

export default RedirectPage

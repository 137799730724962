import { logEvent as firebaseLogEvent } from '@firebase/analytics'
import config from 'config'
import { getAnalytics } from 'firebase/analytics'
import { initializeApp } from 'firebase/app'
import {
  browserLocalPersistence,
  browserSessionPersistence,
  connectAuthEmulator,
  createUserWithEmailAndPassword,
  getAuth,
  getRedirectResult,
  GoogleAuthProvider,
  OAuthProvider,
  sendPasswordResetEmail,
  setPersistence,
  signInWithCustomToken,
  signInWithEmailAndPassword,
  signInWithRedirect,
  updateProfile,
} from 'firebase/auth'
import {
  addDoc,
  collection,
  connectFirestoreEmulator,
  doc,
  getDoc,
  getDocs,
  initializeFirestore,
  limit,
  orderBy,
  query,
  setDoc,
  updateDoc,
  where,
} from 'firebase/firestore'
import {
  connectFunctionsEmulator,
  getFunctions,
  httpsCallable,
} from 'firebase/functions'
import {
  connectStorageEmulator,
  getDownloadURL,
  getStorage,
  ref,
  uploadString,
} from 'firebase/storage'
import EmailAlreadyInUse from './EmailAlreadyInUse'
import UserNotFound from './UserNotFound'

const firebaseApp = initializeApp(config.firebase.config)
const auth = getAuth(firebaseApp)
const db = initializeFirestore(firebaseApp, { ignoreUndefinedProperties: true })

const functions = getFunctions(firebaseApp, 'europe-west1')
const storage = getStorage()
const analytics = getAnalytics()

export const firebaseDateToJs = (date) => {
  return new Date(date.seconds * 1000 + date.nanoseconds / 1000000)
}

if (config.firebase.emulator) {
  console.log('connecting to emulators...')
  connectAuthEmulator(auth, 'http://localhost:9099')
  connectFirestoreEmulator(db, 'localhost', 8080)
  connectFunctionsEmulator(functions, 'localhost', 5001)

  connectStorageEmulator(storage, 'localhost', 9199)
}

export const logEvent = (eventName, data) => {
  firebaseLogEvent(analytics, eventName, data)
}

export const signInWithApple = async (rememberMe = true) => {
  if (rememberMe) {
    setPersistence(auth, browserLocalPersistence)
  } else {
    setPersistence(auth, browserSessionPersistence)
  }

  window.location.hash = 'redirecting'
  await signInWithRedirect(auth, new OAuthProvider('apple.com'))
  return auth.currentUser
}

export const signInWithGoogle = async (rememberMe = true) => {
  if (rememberMe) {
    setPersistence(auth, browserLocalPersistence)
  } else {
    setPersistence(auth, browserSessionPersistence)
  }

  window.location.hash = 'redirecting'
  await signInWithRedirect(auth, new GoogleAuthProvider())
  return auth.currentUser
}

export const signInWithCrendentials = async (
  email,
  password,
  rememberMe = true
) => {
  if (rememberMe) {
    setPersistence(auth, browserLocalPersistence)
  } else {
    setPersistence(auth, browserSessionPersistence)
  }
  try {
    await signInWithEmailAndPassword(auth, email, password)
  } catch (e) {
    if (
      e.code === 'auth/user-not-found' ||
      e.code === 'auth/wrong-password' ||
      e.code === 'auth/invalid-email'
    ) {
      throw new UserNotFound()
    }
    throw e
  }
  return auth.currentUser
}

export const getVehicleBrands = async () => {
  const storageRef = ref(storage, 'manufacturers.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  return (await response.json()).manufacturers
}

export const getInsuranceCompanies = async () => {
  const storageRef = ref(storage, 'insurance_companies.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  return (await response.json()).insuranceCompanies
}

export const getLockBrands = async () => {
  const storageRef = ref(storage, 'lock_brands.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  return (await response.json()).lockBrands
}

export const getCurrencies = async () => {
  const storageRef = ref(storage, 'currencies.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  return (await response.json()).currencies
}

export const getLockCertifications = async () => {
  const storageRef = ref(storage, 'lock_certifications.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  return (await response.json()).lockCertifications
}

export const getRetailers = async (countryId) => {
  const q = query(
    collection(db, 'retailers'),
    where('address.countryId', '==', countryId)
  )
  const snap = await getDocs(q)

  return snap.docs.map((d) => ({ id: d.id, ...d.data() }))
}
export const insertRetailer = async (retailer) => {
  const docRef = await addDoc(collection(db, 'retailers'), {
    retailer,
    approved: false,
  })
  return docRef.id
}

export const getCountries = async () => {
  const storageRef = ref(storage, 'countries.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  const { countries } = await response.json()
  countries.sort((a, b) => a.country.localeCompare(b.country))
  return countries
}

export const getVehicleEngineBrands = async () => {
  const storageRef = ref(storage, 'electric_motors.json')
  const url = await getDownloadURL(storageRef)
  const response = await fetch(url)
  return (await response.json()).electricMotors
}

export const storeVehicleDocument = async (
  vehicleId,
  { fileName, data, extension }
) => {
  const path = `users/${auth.currentUser.uid}/vehicles/${vehicleId}/${fileName}.${extension}`
  const storageRef = ref(storage, path)
  await uploadString(storageRef, data, 'data_url')
  const url = await getDownloadURL(storageRef)

  return {
    path,
    url,
  }
}

export const updateVehicle = async (id, vehicle = {}, files = []) => {
  const vehicleRef = doc(db, 'vehicles', id)

  const newVehicle = vehicle

  let propertyToUrl = {}
  // eslint-disable-next-line no-restricted-syntax
  for (const file of files.filter(Boolean)) {
    if (file.property.includes('[')) {
      // property is from an array
      // extract index
      const index = parseInt(file.property.split('[')[1].split(']')[0], 10)

      const pathBeforeIndex = file.property.split('[')[0]
      const pathAfterIndex = file.property.split('].')[1]
      newVehicle[pathBeforeIndex][index][pathAfterIndex] =
        // eslint-disable-next-line no-await-in-loop
        await storeVehicleDocument(vehicleRef.id, file)
    } else {
      propertyToUrl = {
        // eslint-disable-next-line no-await-in-loop
        [file.property]: await storeVehicleDocument(vehicleRef.id, file),
      }
    }
  }

  await updateDoc(vehicleRef, { ...vehicle, ...propertyToUrl })

  return vehicleRef
}

export const initUser = async (uid, countryId = 'BE') => {
  await setDoc(
    doc(db, 'users', uid),
    {
      countryId,
      distanceUnit: 'kilometers',
      temperatureUnit: 'celsius',
      currency: 'EUR',
    },
    { merge: true }
  )
}

export const signUpWithCredentials = async ({ email, password, countryId }) => {
  try {
    const credentials = await createUserWithEmailAndPassword(
      auth,
      email,
      password
    )
    await initUser(credentials.user.uid, countryId)
  } catch (e) {
    if (e.code === 'auth/email-already-in-use') {
      throw new EmailAlreadyInUse()
    }
    throw e
  }
}

export const getIdTokenAndClaims = async (refresh) => {
  const { claims, token } = await auth.currentUser.getIdTokenResult(refresh)
  return { claims, token }
}

export const getSession = async (refresh = false) => {
  const user = auth.currentUser

  if (!user) {
    return null
  }

  const { token } = await getIdTokenAndClaims(refresh)

  return {
    ...auth.currentUser,
    token,
  }
}

const signinWithToken = async (token) => {
  await signInWithCustomToken(auth, token)
  return auth.currentUser
}

export const signInWithIdToken = async (idToken) => {
  const getCustomToken = httpsCallable(
    functions,
    'payment-token-getCustomToken'
  )
  const { data } = await getCustomToken({ idToken })
  return signinWithToken(data)
}

export const signOut = () => {
  return auth.signOut()
}

export const onAuthStateChanged = (next) => {
  return auth.onAuthStateChanged(next)
}

export const getMe = async () => {
  const userRef = doc(db, 'users', auth.currentUser.uid)
  const userSnap = await getDoc(userRef)
  return {
    // default country
    countryId: 'BE',
    ...auth.currentUser,
    ...userSnap.data(),
  }
}

export const getUserCountry = async () => {
  const user = await getMe()
  const countries = await getCountries()
  const country = countries.find(
    (c) => c.countryCode.toUpperCase() === user.countryId.toUpperCase()
  )
  return country
}

export const updateMe = async (user) => {
  if (user.firstName && user.lastName) {
    const displayName = `${user.firstName} ${user.lastName}`
    if (auth.currentUser.displayName !== displayName)
      await updateProfile(auth.currentUser, {
        displayName,
      })
  }

  const userRef = doc(db, 'users', auth.currentUser.uid)

  await updateDoc(userRef, user)
  return userRef
}

export const updateTrip = async (trip) => {
  const tripRef = doc(db, 'trips', trip.id)

  await updateDoc(tripRef, trip)
  return tripRef
}

export const passwordReset = (email) => sendPasswordResetEmail(auth, email)

export const getUser = async (userId) => {
  const userRef = doc(db, 'users', userId)
  const userSnap = await getDoc(userRef)
  return userSnap.data()
}

export const onRedirect = async () => {
  const result = await getRedirectResult(auth)
  if (result && result.user) {
    const existingUser = await getUser(result.user.uid)
    if (!existingUser) {
      await initUser(result.user.uid)

      return { ...result, isNew: true }
    }
    return { ...result, isNew: false }
  }
  return null
}

export const getVehicle = async (vehicleId) => {
  const vehicleRef = doc(db, 'vehicles', vehicleId)
  const vehicleSnap = await getDoc(vehicleRef)
  return { id: vehicleId, ...vehicleSnap.data() }
}

export const getTracker = async (trackerId) => {
  const trackerRef = doc(db, 'trackers', trackerId)
  const snap = await getDoc(trackerRef)
  return { id: trackerId, ...snap.data() }
}

export const getTripsByVehicle = async (vehicleId) => {
  const q = query(
    collection(db, 'trips'),
    where('vehicleId', '==', vehicleId),
    where('uid', '==', auth.currentUser.uid),
    orderBy('summary.startTime', 'asc')
  )
  const tripsSnap = await getDocs(q)

  return tripsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
}

export const getTrips = async () => {
  const q = query(
    collection(db, 'trips'),
    where('uid', '==', auth.currentUser.uid),
    orderBy('summary.startTime', 'desc'),
    limit(20)
  )
  const tripsSnap = await getDocs(q)

  return tripsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
}

export const getItineraries = async () => {
  const q = query(
    collection(db, 'itineraries'),
    where('uid', '==', auth.currentUser.uid)
  )

  const itineraries = await getDocs(q)

  return itineraries.docs.map((d) => ({ id: d.id, ...d.data() }))
}

export const getVehicles = async () => {
  const q = query(
    collection(db, 'vehicles'),
    where('uid', '==', auth.currentUser.uid)
  )
  const vehiclesSnap = await getDocs(q)

  return vehiclesSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
}

export const addVehicle = async (vehicle, files = []) => {
  const docRef = await addDoc(collection(db, 'vehicles'), {
    uid: auth.currentUser.uid,
    // Boolean must be defined for iOS
    boughtTracker: false,
    hasThirdPartyInsurance: false,
    hasTrackerSubscription: false,
    isLocked: false,
    isElectric: false,
    isSpeedPedelec: false,
    category: 'bicycle',
    summary: {
      tripsDistance: 0,
      tripsDuration: 0,
      tripsElevationGain: 0,
      tripsSavedCO2: 0,
      tripsCalories: 0,
      tripsAverageHeartRate: 0,
      tripsCount: 0,
    },
    ...vehicle,
  })

  if (files && files.length > 0) {
    await updateVehicle(docRef.id, {}, files)
  }
  return docRef
}

export const getPrices = async (productId) => {
  const sweelPrices = httpsCallable(functions, 'payment-sweel-prices')
  const { data } = await sweelPrices({ productId })
  return data
}

export const getInsuranceQuote = async (bikeType, isElectric, value) => {
  const quote = httpsCallable(functions, 'insurance-getQuote')
  const { data } = await quote({
    bikeType,
    isElectric,
    valueInCent: value * 100,
  })

  return data.price
}

export const checkout = async (
  productId,
  priceId,
  successUri,
  cancelUri,
  vehicleId,
  form,
  orderNumber,
  locale = 'auto'
) => {
  const sweelCheckout = httpsCallable(functions, 'payment-sweel-checkout')
  const { data } = await sweelCheckout({
    productId,
    priceId,
    successUrl: `${window.location.origin}/redirect?uri=${successUri}`,
    cancelUrl: `${window.location.origin}/redirect?uri=${cancelUri}`,
    vehicleId,
    orderNumber,
    form,
    locale,
  })

  return data.id
}

export const getCustomerPortalUrl = async (returnUrl) => {
  const createPortalLink = httpsCallable(
    functions,
    'payment-portal-createPortalLink'
  )
  const { data } = await createPortalLink({
    returnUrl: `${window.location.origin}/redirect?uri=${returnUrl}`,
  })
  return data.url
}

export const deleteVehicle = async (vehicleId) => {
  const deleteVehicleCall = httpsCallable(functions, 'delete-deleteVehicle')
  await deleteVehicleCall({ vehicleId })
}

export const deleteUser = async () => {
  const deleteUserCall = httpsCallable(
    functions,
    'delete-deleteAllUserRelatedData'
  )
  await deleteUserCall({ uid: auth.currentUser.uid })
  await auth.signOut()
}

export const getListOfYearMonthWithTrips = async () => {
  const call = httpsCallable(
    functions,
    'expenseReport-getListOfYearMonthWithTrips'
  )
  const { data } = await call()
  return data.months
}

export const postItinerary = async (name, gpxData) => {
  const call = httpsCallable(functions, 'itineraries-uploadItinerary')
  const { data } = await call({ name, gpxData })
  return data.id
}

export const getProfessionalTripsByYearMonth = async (yearMonth) => {
  const year = yearMonth.toString().substring(0, 4)
  const yearMonthWhere =
    yearMonth.toString().length === 4
      ? [
          where('summary.yearMonth', '>=', parseInt(`${year}01`, 10)),
          where('summary.yearMonth', '<=', parseInt(`${year}12`, 10)),
        ]
      : [
          where('summary.yearMonth', '==', parseInt(yearMonth, 10)),
          orderBy('summary.startTime', 'asc'),
        ]

  const tripsSnap = await getDocs(
    query(
      collection(db, 'trips'),
      where('uid', '==', auth.currentUser.uid),
      where('isProfessional', '==', true),
      ...yearMonthWhere
    )
  )

  return tripsSnap.docs
    .map((d) => ({ id: d.id, ...d.data() }))
    .sort(
      (a, b) =>
        firebaseDateToJs(a.summary.startTime) -
        firebaseDateToJs(b.summary.startTime)
    )
}

export const getTripsByYearMonth = async (yearMonth) => {
  const tripsSnap = await getDocs(
    query(
      collection(db, 'trips'),
      where('uid', '==', auth.currentUser.uid),
      where('summary.yearMonth', '==', parseInt(yearMonth, 10)),
      orderBy('summary.startTime', 'asc')
    )
  )

  return tripsSnap.docs.map((d) => ({ id: d.id, ...d.data() }))
}

export const getTrackerEvents = async (vehicleId, startDate, endDate) => {
  const request = httpsCallable(functions, 'tracker-getCoordinates')
  const { data } = await request({
    vehicleId,
    startDate,
    endDate,
  })
  return data.events
}

export const validateOrderNumber = async (orderNumber) => {
  const request = httpsCallable(functions, 'tracker-validateOrderNumber')
  const { data } = await request({
    orderNumber,
  })
  return data.valid
}

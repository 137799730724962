import { getSession, onAuthStateChanged, signInWithIdToken } from 'api/firebase'
import queryString from 'query-string'
import { useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { useLocation } from 'react-router'

export default () => {
  const queryClient = useQueryClient()
  const location = useLocation()

  const [isInitialized, setInitialized] = useState(false)

  const { idToken } = queryString.parse(location.search, { parseNumbers: true })

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(async (_) => {
      if (!isInitialized && idToken) {
        try {
          await signInWithIdToken(idToken)
        } catch (e) {
          console.error(e)
        }
      }

      // beware: onAuthStateChanged is called multiple time without knowing his state
      queryClient.setQueryData('session', await getSession())

      setInitialized(true)
    })

    return unsubscribe
  }, [idToken, isInitialized, queryClient])

  return useQuery('session', getSession, {
    enabled: isInitialized,
    retry: 0,
  })
}

import { BarChart } from '@mui/icons-material'
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import get from 'lodash.get'
import React from 'react'
import { useTranslation } from 'react-i18next'
import metersToFeet from 'utils/metersToFeet'
import metersToMiles from 'utils/metersToMiles'
import BikeCardLine from './BikeCardLine'

export default ({ vehicles, user, isGlobal = false }) => {
  const { t } = useTranslation()

  const isSmallScren = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const vehiclesSum = vehicles.reduce(
    (acc, vehicle) => ({
      distance:
        acc.distance +
        (vehicle.initialDistance || 0) +
        get(vehicle, 'summary.tripsDistance', 0),
      duration: acc.duration + get(vehicle, 'summary.tripsDuration', 0),
      tripsCount: acc.tripsCount + get(vehicle, 'summary.tripsCount', 0),
      elevationGain:
        acc.elevationGain + get(vehicle, 'summary.tripsElevationGain', 0),
      savedCO2: acc.savedCO2 + get(vehicle, 'summary.tripsSavedCO2', 0),
      calories: acc.calories + get(vehicle, 'summary.tripsCalories', 0),
    }),
    {
      distance: 0,
      duration: 0,
      tripsCount: 0,
      elevationGain: 0,
      savedCO2: 0,
      calories: 0,
    }
  )

  const totalDistanceWithUnit = (() => {
    switch (user.distanceUnit) {
      case 'miles':
        return `${Math.floor(metersToMiles(vehiclesSum.distance))} ${t(
          'miles'
        )}`
      default:
        return `${Math.floor(vehiclesSum.distance / 1000)} ${t('km')}`
    }
  })()

  const totalElevationWithUnit = (() => {
    switch (user.distanceUnit) {
      case 'miles':
        return `${Math.floor(metersToFeet(vehiclesSum.elevationGain))} ${t(
          'ft'
        )}`
      default:
        return `${Math.floor(vehiclesSum.elevationGain)}  ${'meters'}`
    }
  })()

  const totalDurationInMin = vehiclesSum.duration / 60
  const totalDurationWithUnit =
    totalDurationInMin > 60
      ? `${Math.floor(totalDurationInMin / 60)} hours`
      : `${Math.floor(totalDurationInMin)} min`

  return (
    <Card sx={{ bgcolor: isGlobal ? 'primary.main' : '' }}>
      <CardHeader
        avatar={<BarChart />}
        title={
          <Typography variant="h6">
            {isGlobal ? t('Global statistics') : t('Statistics')}
          </Typography>
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid item container direction="column" spacing={1}>
              <Grid item>
                <BikeCardLine
                  label={t('Total trips')}
                  value={vehiclesSum.tripsCount}
                />
              </Grid>
              <Grid item>
                <BikeCardLine
                  label={t('Total distance')}
                  value={totalDistanceWithUnit}
                />
              </Grid>
              <Grid item>
                <BikeCardLine
                  label={t('Total time')}
                  value={totalDurationWithUnit}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <BikeCardLine
                  label={t('Total elevation gain')}
                  value={totalElevationWithUnit}
                />
              </Grid>
              <Grid item>
                <BikeCardLine
                  label={t('Total saved CO2')}
                  value={`${(vehiclesSum.savedCO2 / 1000).toFixed(0)} kg`}
                />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}

import { Container, Grid } from '@mui/material'
import {
  addVehicle as addVehicleDoc,
  deleteVehicle as deleteVehicleDoc,
  getMe,
  getVehicles,
} from 'api/firebase'
import BikeStatisticsCard from 'features/bike/BikeStatisticsCard'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import routes from 'routes/routes'
import { showError, showInfoMessage } from 'utils/notifier'
import BikeCard from './BikeCard'
import NoLocationDialog from './NoLocationDialog'
import SaveBikeDialog from './SaveBikeDialog'

const Bikes = () => {
  const { t } = useTranslation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const [saveVehicleDialogOpen, setSaveVehicleDialogOpen] = useState(false)
  const [editVehicle, setEditVehicle] = useState()
  const [dialogNoLocationOpen, setDialogNoLocationOpen] = useState(false)

  const { data: vehicles, isLoading: vehiclesIsLoading } = useQuery(
    'vehicles',
    getVehicles,
    {
      onSuccess: (data) => {
        if (data.length === 0) {
          setSaveVehicleDialogOpen(true)
        }
      },
      placeholderData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { data: user, isLoading: userIsLoading } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const { mutate: addVehicle, isLoading: addVehicleIsLoading } = useMutation(
    ({ vehicle, files }) => addVehicleDoc(vehicle, files),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('vehicles')
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: deleteVehicle, isLoading: deleteVehicleIsLoading } =
    useMutation((vehicle) => deleteVehicleDoc(vehicle.id), {
      onSuccess: () => {
        queryClient.invalidateQueries('vehicles')
        showInfoMessage(snackbar, t('Vehicle removed'))
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const isLoading =
    vehiclesIsLoading ||
    addVehicleIsLoading ||
    deleteVehicleIsLoading ||
    userIsLoading

  return (
    <NavigationMenu progress={isLoading} title={t('Vehicles')}>
      <Container maxWidth="lg">
        <Grid container direction="column" spacing={4} mt={4} mb={4}>
          {vehicles && user && (
            <>
              <Grid item>
                <BikeStatisticsCard vehicles={vehicles} user={user} isGlobal />
              </Grid>
              <Grid item container direction="column" spacing={2}>
                {vehicles.map((vehicle) => (
                  <Grid item key={vehicle.id}>
                    <BikeCard
                      vehicle={vehicle}
                      user={user}
                      onClick={(v) => {
                        history.push(routes.BIKE.replace(':id', v.id))
                      }}
                      onDelete={deleteVehicle}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}
        </Grid>
      </Container>

      <SaveBikeDialog
        open={saveVehicleDialogOpen}
        onClose={() => {
          setEditVehicle(null)
          setSaveVehicleDialogOpen(false)
        }}
        onSaveVehicle={(data) => {
          addVehicle(data)
        }}
        vehicle={editVehicle}
      />
      <NoLocationDialog
        open={dialogNoLocationOpen}
        onClose={() => {
          setDialogNoLocationOpen(false)
        }}
      />
    </NavigationMenu>
  )
}

export default Bikes

import { Button, Container, Grid, Typography } from '@mui/material'
import {
  getCurrencies,
  getCustomerPortalUrl,
  getMe,
  updateMe,
} from 'api/firebase'
import config, { isProductionEnvironment } from 'config'
import NavigationMenu from 'features/menu/NavigationMenu'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { showError, showInfoMessage } from 'utils/notifier'
import packageJson from '../../../package.json'

export default () => {
  const { t } = useTranslation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const {
    handleSubmit,
    control,
    setValue,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'all' })

  const { data: user, isLoading: userIsLoading } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const { mutate: updateUser, isLoading: updateUserIsLoading } = useMutation(
    (u) => updateMe(u),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
        return showInfoMessage(snackbar, t('Settings updated'))
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { data: currencies } = useQuery('currencies', getCurrencies, {
    initialData: [],
    onError: (e) => showError(snackbar, e, t),
  })

  const { refetch: openStripePortal, isLoading: isStripePortalLoading } =
    useQuery(
      'stripe-portal',
      () => getCustomerPortalUrl(window.location.origin),
      {
        onSuccess: (url) => {
          window.location.assign(url)
        },
        enabled: false,
        refetchOnWindowFocus: false,
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const onSubmit = async ({ currency, distanceUnit, temperatureUnit }) => {
    updateUser({
      currency: currency
        ? currencies
          ? currencies.find((c) => c.name === currency).code.toUpperCase()
          : 'EUR'
        : undefined,
      distanceUnit,
      temperatureUnit,
    })
  }

  const selectedCurrency = currencies.find(
    (c) => c.code.toUpperCase() === get(user, 'currency', 'EUR')
  )

  useEffect(() => {
    if (user && currencies && currencies.length > 0) {
      setValue('currency', selectedCurrency ? selectedCurrency.name : 'Euro', {
        shouldValidate: true,
      })
    }
  }, [currencies, selectedCurrency, setValue, user])

  const isFormValid = isValid && isDirty
  const isLoading =
    userIsLoading || updateUserIsLoading || isStripePortalLoading

  return (
    <NavigationMenu title={t('Settings')} progress={isLoading}>
      <Container maxWidth="sm">
        <Grid container direction="column" mt={4}>
          {user && user.stripeCustomerId && (
            <Grid item container direction="column" spacing={2} mt={2}>
              <Grid item>
                <Typography variant="subtitle1" color="text.secondary">
                  {t('Subscription')}
                </Typography>
              </Grid>
              <Grid item>
                <Button variant="outlined" onClick={openStripePortal} fullWidth>
                  {t('Invoices')}
                </Button>
              </Grid>
            </Grid>
          )}
          <Grid item container direction="column" spacing={2} mt={2}>
            <Grid item>
              <Typography variant="subtitle1" color="text.secondary">
                {t('About')}
              </Typography>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                href={t('disclaimerUrl')}
                target="_blank"
                color="secondary"
                fullWidth
              >
                {t('Privacy policy')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                href={t('termsUrl')}
                target="_blank"
                color="secondary"
                fullWidth
              >
                {t('General terms and conditions')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                href={config.faqUrl}
                target="_blank"
                color="secondary"
                fullWidth
              >
                {t('FAQ')}
              </Button>
            </Grid>
            <Grid item>
              <Button
                variant="outlined"
                href={t('contactUrl')}
                target="_blank"
                color="secondary"
                fullWidth
              >
                {t('Contact')}
              </Button>
            </Grid>
          </Grid>
          <Grid item alignSelf="center" mt={4}>
            <Typography variant="subtitle1" color="text.secondary">
              {`Sweel © ${new Date().getFullYear()} - ${
                isProductionEnvironment()
                  ? packageJson.version
                  : `${packageJson.version}-${config.environment}`
              }`}
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </NavigationMenu>
  )
}

import { Button, Container, Grid, Typography } from '@mui/material'
import {
  getMe,
  getProfessionalTripsByYearMonth,
  getTrackerEvents,
  getTripsByVehicle,
  getVehicle,
} from 'api/firebase'
import { subMonths } from 'date-fns'
import { useSnackbar } from 'notistack'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory, useLocation } from 'react-router'
import { showError } from 'utils/notifier'
import { generateHistory, generateStatsReport } from './csvGeneration'
import { generateExpenseReport, generateVehicleInfo } from './pdfGeneration'

export default ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const snackbar = useSnackbar()
  const location = useLocation()
  const { id, document, documentId, variant } = match.params

  const [isLoading, setIsLoading] = React.useState(true)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)
        const user = await getMe()
        const vehicle = id ? await getVehicle(id) : null
        switch (document) {
          case 'info':
            generateVehicleInfo(vehicle, t, user)
            break
          case 'expense': {
            const trips = await getProfessionalTripsByYearMonth(
              parseInt(documentId, 10)
            )

            generateExpenseReport(
              t,
              user,
              trips.sort((a, b) => b.summary.startTime - a.summary.startTime),
              documentId
            )
            break
          }
          case 'trackerHistory': {
            const trackerEvents = await getTrackerEvents(
              id,
              subMonths(new Date(), 0).getTime() / 1000,
              subMonths(new Date(), 1).getTime() / 1000
            )
            generateHistory(trackerEvents, vehicle)
            break
          }
          case 'statistics': {
            const trips =
              documentId === 'all'
                ? await getTripsByVehicle(id)
                : await getProfessionalTripsByYearMonth(
                    id,
                    parseInt(documentId, 10)
                  )

            generateStatsReport(
              t,
              vehicle,
              trips,
              documentId,
              user.distanceUnit
            )
            break
          }
          default:
            showError(snackbar, t('Document not found'), t)
        }
        setIsLoading(false)
      } catch (e) {
        setIsLoading(false)
        console.log(e)
        showError(snackbar, e, t)
      }
    })()
  }, [])

  return (
    <Container maxWidth="xs">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        minHeight="90vh"
      >
        <Grid item>
          <Typography variant="subtitle1">
            {t('Document download will start automatically...')}
          </Typography>
        </Grid>
        <Grid item>
          {(window.opener || window.history.length === 1) && (
            <Button
              onClick={() => {
                window.close()
              }}
            >
              {t('Close window')}
            </Button>
          )}
        </Grid>
      </Grid>
    </Container>
  )
}

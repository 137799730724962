import {
  AppBar,
  Button,
  Container,
  LinearProgress,
  Step,
  StepLabel,
  Stepper,
  Toolbar,
  Typography,
} from '@mui/material'
import {
  addVehicle as addVehicleDoc,
  signUpWithCredentials,
  updateMe,
} from 'api/firebase'
import AddVehicleForm from 'features/common/SaveVehicleForm'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import routes from 'routes/routes'
import { showError } from 'utils/notifier'
import CountryForm from './CountryForm'
import SignUpForm from './SignUpForm'
import steps from './steps'

export default () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const history = useHistory()
  const location = useLocation()

  const [activeStep, setActiveStep] = useState(
    queryString.parse(location.hash).step || steps.account
  )

  const [vehicleId, setVehicleId] = useState()

  const { mutate: signUp, isLoading: signUpIsLoading } = useMutation(
    (data) => signUpWithCredentials(data),
    {
      onSuccess: () => setActiveStep(steps.vehicle),
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: addVehicle, isLoading: addVehicleIsLoading } = useMutation(
    ({ vehicle, picture }) => addVehicleDoc(vehicle, picture),
    {
      onSuccess: (data) => {
        setVehicleId(data.id)
        setActiveStep(steps.country)
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: updateAccount, isLoading: updateAccountIsLoading } =
    useMutation(({ user }) => updateMe(user), {
      onSuccess: (data, { price }) => {
        if (price) {
          history.push(
            `${routes.CHECKOUT.replace(':productId', price.product)}?priceId=${
              price.id
            }${vehicleId ? `&vehicleId=${vehicleId}` : ''}`
          )
        } else {
          history.replace('/')
        }
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const isLoading =
    signUpIsLoading || addVehicleIsLoading || updateAccountIsLoading

  const stepsComponents = {
    [steps.account]: {
      title: t('Create an account'),
      component: <SignUpForm onSignup={signUp} isLoading={signUpIsLoading} />,
    },
    [steps.vehicle]: {
      title: t('Add a vehicle'),
      component: (
        <AddVehicleForm
          onSaveVehicle={addVehicle}
          isLoading={addVehicleIsLoading}
        />
      ),
    },
    [steps.country]: {
      title: t('Select a country'),
      component: (
        <CountryForm
          onSave={(formData, price) => updateAccount({ user: formData, price })}
        />
      ),
    },
  }

  return (
    <>
      <AppBar position="static">
        <Toolbar
          sx={{
            backgroundColor: 'secondary.main',
            '& .MuiTypography-root': {
              color: 'white',
            },
          }}
        >
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {stepsComponents[activeStep].title}
          </Typography>
          <Button href={routes.SIGN_IN} color="inherit">
            {t('Login')}
          </Button>
          {isLoading && <LinearProgress />}
        </Toolbar>
      </AppBar>
      <Container maxWidth={activeStep === steps.country ? 'md' : 'xs'}>
        <>
          {stepsComponents[activeStep].component}
          <Stepper
            sx={{ mt: 8 }}
            activeStep={Object.values(steps).indexOf(activeStep)}
            alternativeLabel
          >
            {Object.values(stepsComponents).map((step) => (
              <Step key={step.title}>
                <StepLabel>{step.title}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </>
      </Container>
    </>
  )
}

import { CircularProgress, Container, Grid } from '@mui/material'
import {
  getSession,
  onRedirect,
  signInWithApple,
  signInWithGoogle,
} from 'api/firebase'
import steps from 'features/signup/steps'
import { useSnackbar } from 'notistack'
import queryString from 'query-string'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useHistory, useLocation } from 'react-router'
import routes from 'routes/routes'
import { showError } from 'utils/notifier'

export default ({ match }) => {
  const { t } = useTranslation()
  const history = useHistory()
  const queryClient = useQueryClient()
  const snackbar = useSnackbar()
  const location = useLocation()
  const { provider } = match.params

  const { mutate: signInApple } = useMutation(signInWithApple, {
    onError: (e) => {
      showError(snackbar, e, t)
      history.goBack()
    },
  })

  const { mutate: signInGoogle } = useMutation(signInWithGoogle, {
    onError: (e) => {
      showError(snackbar, e, t)
      history.goBack()
    },
  })

  useEffect(() => {
    const redirecting =
      queryString.parse(location.hash).redirecting !== undefined

    if (redirecting) {
      ;(async () => {
        try {
          const result = await onRedirect()
          if (result && result.user) {
            queryClient.setQueryData('session', await getSession())
            if (result.isNew) {
              history.replace(`${routes.SIGN_UP}#step=${steps.vehicle}`)
            } else {
              history.replace('/')
            }
          }
        } catch (e) {
          showError(snackbar, e, t)
        }
      })()
    } else if (provider === 'apple') {
      signInApple()
    } else if (provider === 'google') {
      signInGoogle()
    } else {
      history.push(routes.NotFound)
    }
  }, [
    history,
    location.hash,
    provider,
    queryClient,
    signInApple,
    signInGoogle,
    snackbar,
    t,
  ])

  useEffect(() => {
    const timer = setTimeout(() => {
      history.replace('/')
      showError(snackbar, { message: 'Timeout redirecting' }, t)
    }, 10000)
    return () => clearTimeout(timer)
  })

  return (
    <Container maxWidth="xs">
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        spacing={4}
        minHeight="90vh"
      >
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    </Container>
  )
}

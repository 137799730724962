export default {
  PROFILE: '/profile',
  SIGN_IN: '/sign-in',
  GET_INSURANCE_QUOTE: '/get-insurance-quote',
  GET_PRODUCT_PRICES: '/get-product-prices',
  SIGN_IN_REDIRECTION: '/sign-in/redirection/:provider',
  FORGOT_PASSWORD: '/reset-password',
  SIGN_UP: '/sign-up',
  SETTINGS: '/settings',
  TRIPS: '/trips',
  BIKES: '/vehicles',
  BIKE: '/vehicles/:id',
  BIKE_DOCUMENT: '/vehicles/:id/document/:document',
  BIKE_DOCUMENT_ID: '/vehicles/:id/document/:document/:documentId',
  CHECKOUT: '/payment/checkout/:productId',
  CHECKOUT_INSURANCE: '/payment/checkout',
  CUSTOMER_PORTAL: '/payment/portal',
  CHECKOUT_SUCCESS: '/payment/success',
  CHECKOUT_CANCEL: '/payment/cancel',
  NotFound: '/404',
  DOCUMENT_ID: '/document/:document/:documentId',
  EXPENSE_REPORT: '/expense-report',
  EXPENSE_REPORT_YEARMONTH: '/expense-report/:yearMonth',
}

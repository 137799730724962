import { firebaseDateToJs } from 'api/firebase'
import JsPDF from 'jspdf'
import get from 'lodash/get'
import metersToFeet from 'utils/metersToFeet'
import metersToMiles from 'utils/metersToMiles'

require('jspdf-autotable')

function getImgFromUrl(imageUrl, callback) {
  if (imageUrl) {
    const img = new Image()
    img.src = imageUrl
    img.onload = () => {
      callback(img, img.width, img.height)
    }
  } else {
    callback(null, 0, 0)
  }
}

export const generateVehicleInfo = (vehicle, t, user) => {
  getImgFromUrl(
    get(vehicle, 'picture.url', null),
    (img, imgWidth, imgHeight) => {
      const doc = new JsPDF({ compress: true })

      let cursor = 10

      doc.addImage('/logo_plain.png', 'png', 180, 10, 20, 20)
      doc.setFontSize(22)
      doc.text(vehicle.name, 10, cursor)

      cursor += 30
      if (img) {
        const imgRatio = imgWidth / imgHeight

        const width = 80 * (imgRatio > 1 ? imgRatio : 1)
        const height = 80 * (imgRatio < 1 ? imgRatio : 1)
        doc.addImage(img, 'JPEG', 210 / 2 - width / 2, cursor, width, height)
        cursor += 80 * (imgRatio < 1 ? imgRatio : 1) + 30
      }

      doc.setFontSize(12)

      const totalDurationInMin = get(vehicle, 'summary.tripsDuration', 0) / 60
      const totalDurationWithUnit =
        totalDurationInMin > 60
          ? `${Math.floor(totalDurationInMin / 60)} ${t('hours')}`
          : `${Math.floor(totalDurationInMin)} ${t('min')}`

      const totalDistanceWithUnit = (() => {
        const distance = get(vehicle, 'summary.tripsDistance', 0)
        switch (user.distanceUnit) {
          case 'miles':
            return `${Math.floor(metersToMiles(distance))} ${t('miles')}`
          default:
            return `${Math.floor(distance / 1000)} ${t('km')}`
        }
      })()

      const totalElevationWithUnit = (() => {
        const elevationGain = get(vehicle, 'summary.tripsElevationGain', 0) || 0
        switch (user.distanceUnit) {
          case 'miles':
            return `${Math.floor(metersToFeet(elevationGain))} ${t('ft')}`
          default:
            return `${Math.floor(elevationGain)} ${'meters'}`
        }
      })()

      const categoriesProperties = [
        {
          [t('Vehicle info')]: [
            { [t('License plate')]: vehicle.licensePlate },
            { [t('Serial number')]: vehicle.serial },
            { [t('Brand')]: vehicle.make },
            { [t('Model')]: vehicle.model },
            { [t('Color')]: vehicle.color },
            { [t('Purchase date')]: vehicle.purchaseDate },
            { [t('Type')]: t(vehicle.type) },
            { [t('Electric')]: vehicle.isElectric ? t('Yes') : t('No') },
            { [t('Pedelec')]: vehicle.isSpeedPedelec ? t('Yes') : t('No') },
            { [t('Engine manufacturer')]: vehicle.engineManufacturer },
            { [t('Battery capacity (Wh)')]: vehicle.batteryCapacity },
            { [t('Engine power (w)')]: vehicle.enginePower },
            {
              [t('Last location')]: vehicle.lastLocation
                ? `${firebaseDateToJs(
                    get(vehicle.lastLocation, 'timestamp')
                  ).toLocaleDateString()}:  ${get(
                    vehicle.lastLocation,
                    'latitude'
                  )}, ${get(vehicle.lastLocation, 'longitude')}`
                : undefined,
            },
          ],
        },
        {
          [t('Statistics')]: [
            {
              [t('Trips count')]: get(vehicle, 'summary.tripsCount', 0),
            },
            {
              [t('Distance')]: totalDistanceWithUnit,
            },
            {
              [t('Total duration')]: totalDurationWithUnit,
            },
            {
              [t('Total positive elevation')]: totalElevationWithUnit,
            },
            {
              [t('Saved CO2')]: `${
                get(vehicle, 'summary.tripsSavedCO2', 0) / 1000
              } ${t('kg')}`,
            },

            {
              [t('Calories')]: get(vehicle, 'summary.tripsCalories', 0),
            },
          ],
        },
        {
          [t('Locks')]: (vehicle.locks || [])
            .map((lock, index) => [
              {
                [`${index + 1} - ${t('Brand')}`]: lock.brand,
              },
              {
                [`${index + 1} - ${t('Certification')}`]: lock.certification,
              },
              {
                [`${index + 1} - ${t('Protection level')}`]:
                  lock.protectionLevel,
              },
              {
                [`${index + 1} - ${t('Type')}`]: t(lock.type),
              },
            ])
            .flat(),
        },
        {
          [t('Insurance')]: [
            {
              [t('Company')]: get(
                vehicle,
                'thirdPartyInsurance.insuranceCompany'
              ),
            },
            {
              [t('Contract number')]: get(
                vehicle,
                'thirdPartyInsurance.contractNumber'
              ),
            },
            {
              [t('Contract start date')]: get(
                vehicle,
                'thirdPartyInsurance.contractStartDate'
              ),
            },
            {
              [t('Emergency phone number')]: get(
                vehicle,
                'thirdPartyInsurance.emergencyPhoneNumber'
              ),
            },
          ],
        },
        {
          [t('Maintenance')]: [
            {
              [t('Retailer name')]: get(vehicle, 'maintenance.retailer.name'),
            },
          ],
        },
      ]

      categoriesProperties.forEach((category) => {
        const [title, elements] = Object.entries(category)[0]
        if (elements) {
          if (cursor >= 297 - 10) {
            doc.addPage()
            cursor = 10
          }
          doc.setFontSize(16)
          doc.text(t(title), 10, cursor)
          cursor += 10
        }

        doc.setFontSize(12)
        elements.forEach((kv) => {
          const [key, value] = Object.entries(kv)[0]
          if (value !== undefined && value !== '') {
            if (cursor >= 297 - 10) {
              doc.addPage()
              cursor = 10
            }
            doc.text(`${t(key)}: ${t(value)}`, 10, cursor)
            cursor += 10
          }
        })
        cursor += 10
      })

      doc.save(`${vehicle.name}-info.pdf`)
    }
  )
}

export const isTripValidForExport = (trip, month) => {
  return trip.sections &&
    trip.sections.length > 0 &&
    trip.sections[0].coordinates.length > 1 &&
    month
    ? firebaseDateToJs(
        trip.sections[trip.sections.length - 1].endTime
      ).getMonth() === parseInt(month, 10)
    : true
}

export const generateExpenseReport = (t, user, trips, documentId) => {
  const distanceUnit = get(user, 'distanceUnit', 'kilometers')
  const price = get(user, 'expenseReportConfiguration.price', 0.35)
  const currency = get(user, 'currency', 'EUR')

  const data = trips.map((trip) => {
    const { endTime, startCoordinate, endCoordinate } = trip.summary
    const distanceInMeters = trip.summary.distance
    const distance =
      distanceUnit === 'miles'
        ? metersToMiles(distanceInMeters)
        : distanceInMeters / 1000

    return {
      distance: `${distance.toLocaleString('en-US', {
        minimumIntegerDigits: 1,
        maximumFractionDigits: 2,
        useGrouping: false,
      })} ${distanceUnit}`,
      date: firebaseDateToJs(endTime).toLocaleDateString(),
      time: firebaseDateToJs(endTime).toLocaleTimeString(),
      coordinatesStart: startCoordinate
        ? `${startCoordinate.latitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}, ${startCoordinate.longitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}`
        : t('No GPS data'),
      coordinatesEnd: endCoordinate
        ? `${endCoordinate.latitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}, ${endCoordinate.longitude.toLocaleString('en-US', {
            maximumFractionDigits: 4,
            useGrouping: false,
          })}`
        : t('No GPS data'),
      price,
      total: (distance * price).toLocaleString('en-US', {
        maximumFractionDigits: 2,
        useGrouping: false,
      }),
    }
  })

  const number = documentId

  const doc = new JsPDF()

  doc.addImage('/logo_plain.png', 'png', 180, 10, 20, 20)
  doc.setFontSize(22)
  doc.text(t(`Bike Expense`), 10, 20)
  doc.setFontSize(12)
  doc.text(t(`Number: ${number}`), 10, 30)
  doc.text(t(`Date: ${documentId}`), 10, 35)

  doc.text(t(`Company: ${get(user, 'companyName', '')}`), 10, 45)
  doc.text(t(`VAT Number: ${get(user, 'vatNumber', '')}`), 10, 50)
  doc.text(t(`First Name: ${get(user, 'firstName', '')}`), 10, 55)
  doc.text(t(`Last Name: ${get(user, 'lastName', '')}`), 10, 60)
  if (
    get(user, 'address.street') &&
    get(user, 'address.number') &&
    get(user, 'address.zip') &&
    get(user, 'address.city')
  ) {
    doc.text(
      t(
        `Address: ${get(user, 'address.street', '')} ${get(
          user,
          'address.number',
          ''
        )} ${get(user, 'address.zip', '')} ${get(user, 'address.city', '')}`
      ),
      10,
      65
    )
  } else {
    doc.text(t(`Address: ${''}`), 10, 65)
  }

  let height = 0
  doc.autoTable({
    styles: { halign: 'right' },
    headStyles: { fillColor: [128, 128, 128] },
    head: [
      [
        t('Distance'),
        t('Date'),
        t('Time'),
        t('Start GPS position'),
        t('End GPS position'),
        t('Price'),
        t('Total'),
      ],
    ],
    body: data.map((d) => Object.values(d)),
    margin: { top: 70, right: 10 },
    didDrawPage: (d) => {
      height = d.cursor.y
    },
  })

  doc.setFontSize(14)
  doc.text(
    `${t('Total')}: ${data
      .reduce((acc, v) => acc + Number(v.total), 0)
      .toLocaleString('en-US', {
        maximumFractionDigits: 2,
        useGrouping: false,
      })} ${currency}`,
    200,
    height + 10,
    null,
    null,
    'right'
  )

  doc.save(`report-${number}.pdf`)
}

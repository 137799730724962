import { TextField } from '@mui/material'
import React from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default ({
  control,
  name,
  defaultValue = '',
  rules,
  label,
  ...textFieldProps
}) => {
  const { t } = useTranslation()

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: textFieldProps.required ? t('Required') : false,
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <TextField
          {...field}
          {...textFieldProps}
          label={label}
          variant="outlined"
          helperText={error ? error.message : textFieldProps.helperText}
          error={!!error}
        />
      )}
    />
  )
}

const config = {
  environment: process.env.REACT_APP_ENVIRONMENT_NAME || process.env.NODE_ENV,
  firebase: {
    config: {
      apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
      authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
      projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
      storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.REACT_APP_FIREBASE_SENDER_ID,
      appId: process.env.REACT_APP_FIREBASE_APP_ID,
      measurementId: process.env.REACT_APP_FIREBASE_APP_MEASUREMENT_ID,
    },
    emulator: process.env.REACT_APP_FIREBASE_EMULATOR === 'true' || false,
  },
  googleApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  stripe: {
    apiKey: process.env.REACT_APP_STRIPE_API_KEY,
    productPremium: process.env.REACT_APP_STRIPE_PRODUCT_PREMIUM,
    productInsurance: process.env.REACT_APP_STRIPE_PRODUCT_INSURANCE,
    productUltimate: process.env.REACT_APP_STRIPE_PRODUCT_ULTIMATE,
  },
  bikeType: ['cityBike', 'racingBike', 'mountainBike', 'cargoBike'],
  temperatureUnit: ['celsius', 'fahrenheit'],
  distanceUnit: ['kilometers', 'miles'],
  employmentStatus: ['employed', 'selfEmployed', 'other'],
  qover: {
    productInformationUrl: (language = 'en', country = 'BE') => {
      return `https://api.prd.qover.io/documents/download?apikey=pk_A5EB2C73A5722E8C44BE&productReference=BIKE&country=${country}&language=${language}&type=IPID`
    },
    generalConditionsUrl: (language = 'en', country = 'BE') => {
      return `https://api.prd.qover.io/documents/download?apikey=pk_A5EB2C73A5722E8C44BE&productReference=BIKE&country=${country}&language=${language}&type=generalConditions`
    },

    dataPrivacyPolicyUrl: 'https://www.qover.com/terms-and-policies#data',
    checkout: (language = 'en') =>
      `https://app.qoverme.com/bike/quote/?locale=${language}-BE&key=pk_A5EB2C73A5722E8C44BE&id=0fc849be-b588-4d80-b244-8828d79d1dfe`,
    insuranceUrl: (language = 'en') =>
      ` https://app.qoverme.com/bike/quote/?locale=${language}-BE&key=pk_A5EB2C73A5722E8C44BE&promocode=SWEEL15`,
  },
  termsUrl: 'https://app.sweel.com/terms-and-conditions/',
  privacyUrl: 'https://app.sweel.com/data-privacy/',
  appStoreUrl:
    'https://apps.apple.com/us/app/sweel-bike-dashboard/id1541803697?itscg=30200&itsct=apps_box_promote_link',
  playStoreUrl:
    'https://play.google.com/store/apps/details?id=com.devside.sweel',
  faqUrl: 'https://app.sweel.com/faq/',
  contactUrl: 'https://app.sweel.com/contact/',
  shopUrl: 'https://app.sweel.com/product/sweelsx3/',
  supportUrl: 'https://app.sweel.com/protect/support/',
}

export const isProductionEnvironment = () => {
  return config.environment === 'production'
}

export default config

import NavigationDrawer from 'components/NavigationDrawer'
import React from 'react'
import { useTranslation } from 'react-i18next'
import menu from './menu'

export default (props) => {
  const { t } = useTranslation()

  return <NavigationDrawer logo="/logo.png" menu={menu(t)} {...props} />
}

export const showError = (snackbar, e, t) => {
  snackbar.enqueueSnackbar(
    e.message ? t(e.message) : t('Oops, something went wrong'),
    {
      variant: 'error',
    }
  )
}

export const showErrorMessage = (snackbar, message) =>
  snackbar.enqueueSnackbar(message, {
    variant: 'error',
  })

export const showInfoMessage = (snackbar, message) =>
  snackbar.enqueueSnackbar(message, {
    variant: 'info',
  })

import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  InputLabel,
  Link,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import config from 'config'
import { format, formatISO, parse } from 'date-fns'
import get from 'lodash.get'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'

const CheckoutForm = ({ loading, onSubmit, data, errorFields = [] }) => {
  const { t } = useTranslation()

  const { control, handleSubmit, formState } = useForm({
    mode: 'all',
  })
  const { errors } = formState

  const [phoneNumber, setPhoneNumber] = useState(
    get(data, 'policyholder.phone', '')
  )
  const [birthdate, setBirthdate] = useState(
    new Date(get(data, 'policyholder.birthDate', '2000-01-01'))
  )
  const [gender, setGender] = useState(get(data, 'policyholder.gender', ''))
  const [street, setStreet] = useState(
    get(data, 'policyholder.address.street', '')
  )
  const [number, setNumber] = useState(
    get(data, 'policyholder.address.number', '')
  )
  const [box, setBox] = useState(get(data, 'policyholder.address.box', ''))

  const [newBike, setNewBike] = useState(
    get(data, 'policyholder.vehicle.newBike', true)
  )

  const [serialNumber, setSerialNumber] = useState(
    get(data, 'vehicle.serial', '')
  )

  const [purchaseDate, setPurchaseDate] = useState(
    parse(
      get(data, 'vehicle.purchaseDate', format(new Date(), 'yyyyMMdd')),
      'yyyyMMdd',
      new Date()
    )
  )

  const [legalChecked, setLegalChecked] = useState(false)
  const [contractualInformationChecked, setcontractualInformationChecked] =
    useState(false)

  const onHandleSubmit = async (formData) => {
    const address = {
      street,
      number,
      box,
      zip: formData.zipCode,
      city: formData.city,
    }

    const newData = {
      firstName: formData.firstName,
      lastName: formData.lastName,
      address,
      companyName: formData.companyName,
      vatNumber: formData.vatNumber,
      insurance: {
        policyholder: {
          firstName: formData.firstName,
          lastName: formData.lastName,
          phone: phoneNumber,
          birthDate: formatISO(birthdate, { representation: 'date' }),
          gender,
          address,
        },
        vehicle: {
          serialNumber,
          newBike,
          bikePurchaseDate: formatISO(purchaseDate, { representation: 'date' }),
        },
      },
    }

    onSubmit(newData)
  }

  const isFormValid =
    formState.isValid &&
    phoneNumber &&
    birthdate &&
    gender &&
    street &&
    number &&
    serialNumber &&
    legalChecked &&
    contractualInformationChecked &&
    purchaseDate

  return (
    <Box>
      <form onSubmit={handleSubmit(onHandleSubmit)}>
        <Grid container spacing={4}>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.secondary">
                  {t('Policy holder')}
                </Typography>
              </Grid>

              <Grid item xs={12} sm={6}>
                <Controller
                  name="firstName"
                  control={control}
                  defaultValue={get(data, 'policyholder.firstName', '')}
                  rules={{
                    required: true,
                    maxLength: 25,
                    pattern: {
                      value: /^([^0-9]*)$/,
                      message: t('Invalid first name'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      required
                      fullWidth
                      label={t('First name')}
                      helperText={
                        errors.firstName ? errors.firstName.message : null
                      }
                      error={
                        errors.firstName !== undefined ||
                        errorFields.includes('policyholder.firstName')
                      }
                      autoFocus
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="lastName"
                  control={control}
                  defaultValue={get(data, 'policyholder.lastName', '')}
                  rules={{
                    required: true,
                    maxLength: 25,
                    pattern: {
                      value: /^([^0-9]*)$/,
                      message: t('Invalid last name'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      required
                      fullWidth
                      label={t('Last name')}
                      helperText={
                        errors.lastName ? errors.lastName.message : null
                      }
                      error={
                        errors.lastName !== undefined ||
                        errorFields.includes('policyholder.lastName')
                      }
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12}>
                <TextField
                  variant="outlined"
                  required
                  fullWidth
                  label={t('Phone number')}
                  value={phoneNumber}
                  error={errorFields.includes('policyholder.phone')}
                  onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl fullWidth required variant="outlined">
                  <InputLabel>{t('Gender')}</InputLabel>
                  <Select
                    label={t('Gender')}
                    value={gender}
                    onChange={(e) => setGender(e.target.value)}
                  >
                    <MenuItem value="GENDER_MALE">{t('Male')}</MenuItem>
                    <MenuItem value="GENDER_FEMALE">{t('Female')}</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label={t('Birthdate')}
                    value={birthdate}
                    onChange={(date) => setBirthdate(date)}
                    renderInput={(props) => <TextField {...props} />}
                  />
                </LocalizationProvider>
              </Grid>
              <Grid item xs={12} sm={7}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  label={t('Street')}
                  value={street}
                  onChange={(e) => setStreet(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={3}>
                <TextField
                  variant="outlined"
                  fullWidth
                  required
                  label={t('Number')}
                  value={number}
                  onChange={(e) => setNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={6} sm={2}>
                <TextField
                  variant="outlined"
                  fullWidth
                  label={t('Box')}
                  value={box}
                  onChange={(e) => setBox(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="zipCode"
                  control={control}
                  defaultValue={get(data, 'policyholder.address.zip', '')}
                  rules={{
                    required: true,
                    maxLength: 15,
                    pattern: {
                      value: /^[0-9]*$/,
                      message: t('Invalid zip code'),
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      required
                      label={t('Zip code')}
                      error={
                        errors.zipCode ||
                        errorFields.includes('policyholder.address.zip')
                      }
                      helperText={
                        errors.zipCode ? errors.zipCode.message : null
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <Controller
                  name="city"
                  control={control}
                  defaultValue={get(data, 'policyholder.address.city', '')}
                  rules={{
                    maxLength: 30,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      required
                      label={t('City')}
                      helperText={errors.city ? errors.city.message : null}
                      error={
                        errors.city ||
                        errorFields.includes('policyholder.address.city')
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.secondary">
                  {t('Company')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="companyName"
                  control={control}
                  defaultValue={get(data, 'companyName', '')}
                  rules={{
                    maxLength: 30,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label={t('Company name')}
                      helperText={
                        errors.companyName ? errors.company.message : null
                      }
                      error={
                        errors.companyName ||
                        errorFields.includes('companyName')
                      }
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12}>
                <Controller
                  name="vatNumber"
                  control={control}
                  defaultValue={get(data, 'vatNumber', '')}
                  rules={{
                    maxLength: 25,
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      variant="outlined"
                      fullWidth
                      label={t('VAT number')}
                      helperText={
                        errors.vatNumber ? errors.vatNumber.message : null
                      }
                      error={
                        errors.vatNumber || errorFields.includes('vatNumber')
                      }
                    />
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item>
            <Grid container spacing={2}>
              <Grid item>
                <Typography variant="subtitle1" color="text.secondary">
                  {t('Vehicle')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={newBike}
                      onChange={(e) => setNewBike(e.target.checked)}
                    />
                  }
                  label={t('first user of the bike ?')}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  required
                  label={t('Serial')}
                  value={serialNumber}
                  fullWidth
                  variant="outlined"
                  onChange={(e) => setSerialNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12}>
                <Grid item xs={12} sm={6}>
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      fullWidth
                      required
                      inputVariant="outlined"
                      label={t('Purchase date')}
                      value={purchaseDate}
                      onChange={(date) => setPurchaseDate(date)}
                      renderInput={(props) => <TextField {...props} />}
                    />
                  </LocalizationProvider>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="subtitle1" color="text.secondary">
                  {t('Legals')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={legalChecked}
                      onChange={(e) => setLegalChecked(e.target.checked)}
                    />
                  }
                  label={
                    <Trans i18nKey="acceptLegals">
                      I accept to subscribe to an insurance and confirm that I
                      have read, agreed with and have saved the{' '}
                      <Link
                        underline="always"
                        href={config.qover.generalConditionsUrl()}
                        target="_blank"
                      >
                        terms and conditions
                      </Link>
                      ,{' '}
                      <Link
                        underline="always"
                        href={config.qover.dataPrivacyPolicyUrl}
                        target="_blank"
                      >
                        privacy statement
                      </Link>{' '}
                      and{' '}
                      <Link
                        underline="always"
                        href={config.qover.productInformationUrl()}
                        target="_blank"
                      >
                        product information sheet
                      </Link>
                      .
                    </Trans>
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <FormControlLabel
                  required
                  control={
                    <Checkbox
                      checked={contractualInformationChecked}
                      onChange={(e) =>
                        setcontractualInformationChecked(e.target.checked)
                      }
                    />
                  }
                  label={t(
                    'I agree to receive this (pre)contractual information electronically'
                  )}
                />
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={12} align="center">
            {loading ? (
              <CircularProgress />
            ) : (
              <Button
                type="submit"
                variant="contained"
                disableElevation
                size="large"
                text={t('Continue to payment method')}
                disabled={!isFormValid}
                fullWidth
              >
                {t('Continue to payment method')}
              </Button>
            )}
          </Grid>
        </Grid>
      </form>
    </Box>
  )
}

export default CheckoutForm

import {
  AddModerator,
  Call,
  HealthAndSafety,
  LockOpen,
  Security,
} from '@mui/icons-material'
import {
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material'
import { getInsuranceQuote } from 'api/firebase'
import FormTextField from 'components/FormTextField'
import config from 'config'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { showError } from 'utils/notifier'

export default ({ vehicle, user, onGetInsurance }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'all' })

  const [isLoading, setIsLoading] = useState(false)
  const [quote, setQuote] = useState()
  const [valueForCheckout, setValueForCheckout] = useState()

  const onSubmit = async ({ type, isElectric, value }) => {
    try {
      setIsLoading(true)
      setQuote(
        await getInsuranceQuote(
          type || vehicle.type,
          isElectric === undefined ? vehicle.isElectric : isElectric,
          value
        )
      )
      setValueForCheckout(value * 100)
    } catch (e) {
      showError(snackbar, e, t)
    }
    setIsLoading(false)
  }

  console.log(quote)
  const isFormValid = isValid && isDirty

  return (
    <Grid
      container
      direction="column"
      justifyContent="center"
      alignItems="center"
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
    >
      <Grid item>
        <AddModerator color="inherit" sx={{ width: 75, height: 75 }} />
      </Grid>

      <Grid item>
        <Stack spacing={1}>
          <Stack direction="row" alignItems="center" spacing={1}>
            <LockOpen />
            <Typography>{t('Bike theft')}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Call />
            <Typography>{t('24/7 assistance')}</Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <HealthAndSafety />
            <Typography variant="body2">
              {t('Vandalism or accident')}
            </Typography>
          </Stack>
          <Stack direction="row" alignItems="center" spacing={1}>
            <Security />
            <Typography>{t('Total loss')}</Typography>
          </Stack>
        </Stack>
      </Grid>

      {isLoading ? (
        <Grid item>
          <CircularProgress />
        </Grid>
      ) : quote ? (
        <Grid item>
          <Button
            variant="contained"
            onClick={() => onGetInsurance(valueForCheckout)}
          >
            {t('Get insurance for ')} {quote / 100} € {[t('per year')]}
          </Button>
        </Grid>
      ) : (
        <Grid item>
          <Stack spacing={2}>
            <Stack
              direction="row"
              justifyContent="center"
              alignItems="center"
              spacing={1}
            >
              {(!vehicle || !vehicle.type) && (
                <Controller
                  name="type"
                  control={control}
                  defaultValue={get(vehicle, 'type', '')}
                  rules={{
                    required: t('Required'),
                  }}
                  render={({
                    field: { value, onChange },
                    fieldState: { error },
                  }) => (
                    <FormControl fullWidth required error={!!error}>
                      <InputLabel>{t('Type')}</InputLabel>
                      <Select
                        value={value}
                        label={`${t('Type')} *`}
                        onChange={onChange}
                      >
                        {config.bikeType.map((type) => (
                          <MenuItem key={type} value={type}>
                            {t(type)}
                          </MenuItem>
                        ))}
                      </Select>
                      {error && (
                        <FormHelperText error={!!error}>
                          {error.message}
                        </FormHelperText>
                      )}
                    </FormControl>
                  )}
                />
              )}
              {(!vehicle || vehicle.isElectric === undefined) && (
                <Controller
                  name="isElectric"
                  control={control}
                  defaultValue={get(vehicle, 'isElectric', false)}
                  render={({ field: { value, onChange } }) => (
                    <FormControlLabel
                      control={<Checkbox checked={value} onChange={onChange} />}
                      label={t('Electric')}
                    />
                  )}
                />
              )}
            </Stack>

            <FormTextField
              name="value"
              required
              control={control}
              label={t(`Vehicle value`)}
              defaultValue=""
              fullWidth
              rules={{
                pattern: {
                  value: /^\d*\.?|,?\d+$/,
                  message: t('Invalid price'),
                },
                min: { value: 250, message: t('Minimum value is 250 euros') },
                max: {
                  value: 10000,
                  message: t('Maximum value is 10000 euros'),
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">€</InputAdornment>
                ),
              }}
            />

            <Button
              variant="contained"
              type="submit"
              fullWidth
              disabled={!isFormValid || isLoading}
            >
              {t('Get quote')}
            </Button>
          </Stack>
        </Grid>
      )}
    </Grid>
  )
}

import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Box } from '@mui/system'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import routes from 'routes/routes'
import GetQuote from './GetQuote'

export default ({ open, onClose, vehicle, user }) => {
  const { t } = useTranslation()
  const history = useHistory()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {t('Get an insurance')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box mt={2} mb={2}>
          <GetQuote
            vehicle={vehicle}
            user={user}
            onGetInsurance={(value) =>
              history.push(
                `${routes.CHECKOUT_INSURANCE}?vehicleId=${vehicle.id}&originalValue=${value}`
              )
            }
          />
        </Box>
      </DialogContent>
    </Dialog>
  )
}

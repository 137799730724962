import { Close } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  Stack,
  Typography,
} from '@mui/material'
import { getCurrencies } from 'api/firebase'
import FormTextField from 'components/FormTextField'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'

export default ({ open, onClose, onSave, vehicle, user }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const { data: currencies } = useQuery('currencies', getCurrencies, {
    initialData: [],
    onError: (e) => showError(snackbar, e, t),
  })

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'all' })

  const onSubmit = async ({
    unitPrice,
    emailNotification,
    vatNumber,
    companyName,
  }) => {
    onClose()
    onSave({
      unitPrice: parseFloat(unitPrice.replace(',', '.')),
      emailNotification,
      vatNumber,
      companyName,
    })
  }

  const isFormValid = isValid && isDirty

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('Expense report')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={2}
        >
          <Grid item>
            {currencies && (
              <FormTextField
                name="unitPrice"
                required
                control={control}
                label={t(`Unit price`)}
                defaultValue={get(
                  vehicle,
                  'expenseReportConfiguration.unitPrice',
                  ''
                ).toString()}
                fullWidth
                rules={{
                  pattern: {
                    value: /^\d*\.?|,?\d+$/,
                    message: t('Invalid price'),
                  },
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      {get(
                        currencies.find(
                          (c) =>
                            c.code.toUpperCase() === user.currency.toUpperCase()
                        ),
                        'symbol',
                        '€'
                      )}
                    </InputAdornment>
                  ),
                }}
              />
            )}
          </Grid>
          <Grid item>
            <Controller
              name="emailNotification"
              control={control}
              defaultValue={get(
                vehicle,
                'expenseReportConfiguration.emailNotification',
                false
              )}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  required
                  control={<Checkbox checked={value} onChange={onChange} />}
                  label={t('Email notification')}
                />
              )}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" color="text.secondary">
              {['Company']}
            </Typography>
          </Grid>
          <Grid item>
            <FormTextField
              control={control}
              name="companyName"
              defaultValue={get(
                vehicle,
                'expenseReportConfiguration.companyName',
                get(user, 'companyName', '')
              )}
              label={t('Company name')}
              fullWidth
            />
          </Grid>
          <Grid item>
            <FormTextField
              control={control}
              name="vatNumber"
              defaultValue={get(
                vehicle,
                'expenseReportConfiguration.vatNumber',
                get(user, 'vatNumber', '')
              )}
              label={t('VAT number')}
              fullWidth
            />
          </Grid>
          <Grid item>
            <Stack spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isFormValid}
              >
                {t('Save')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

import { Close } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { getMe } from 'api/firebase'
import Products from 'features/products/Products'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { useHistory } from 'react-router-dom'
import routes from 'routes/routes'
import { showError } from 'utils/notifier'

export default ({ open, onClose, vehicleId }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const history = useHistory()

  const { data: user } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const handleOnSubmit = async (price) => {
    history.push(
      `${routes.CHECKOUT.replace(':productId', price.product)}?priceId=${
        price.id
      }${vehicleId ? `&vehicleId=${vehicleId}` : ''}`
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <DialogTitle>
        {t('Choose plan')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {user && (
          <Products onSubmit={(price) => handleOnSubmit(price)} user={user} />
        )}
      </DialogContent>
    </Dialog>
  )
}

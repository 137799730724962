import {
  PedalBikeOutlined,
  Person2Outlined,
  SettingsOutlined,
  TableView,
} from '@mui/icons-material'
import routes from 'routes/routes'

export default (t) => [
  {
    items: [
      {
        title: t('Report'),
        icon: TableView,
        path: routes.EXPENSE_REPORT,
      },
      {
        title: t('Vehicles'),
        icon: PedalBikeOutlined,
        path: routes.BIKES,
      },
    ],
  },
  {
    items: [
      {
        title: t('Account'),
        icon: Person2Outlined,
        path: routes.PROFILE,
      },
      {
        title: t('Settings'),
        icon: SettingsOutlined,
        path: routes.SETTINGS,
      },
    ],
  },
]

export default {
  picture: {
    fileName: 'picture',
    property: 'picture',
  },
  greenCard: {
    fileName: 'insurance-certificate',
    property: 'insuranceCertificate',
    pedelecOnly: true,
  },
  registrationCertificate: {
    fileName: 'registration-certificate',
    property: 'registrationCertificate',
    pedelecOnly: true,
  },
  invoice: {
    fileName: 'invoice',
    property: 'invoice',
    pedelecOnly: false,
  },
  thirdPartyInsurance: {
    contract: {
      fileName: 'insurance-contract',
      property: 'thirdPartyInsurance.contract',
    },
  },
}

import NotFoundPage from 'components/NotFoundPage'
import Progress from 'components/Progress'
import RedirectPage from 'components/RedirectPage'
import Bike from 'features/bike/Bike'
import Bikes from 'features/bikes/Bikes'
import SignInRedirection from 'features/common/SignInRedirection'
import DocumentRedirection from 'features/document/DocumentRedirection'
import Index from 'features/Index'
import GetInsuranceQuote from 'features/insurance/GetInsuranceQuote'
import Checkout from 'features/payment/Checkout'
import Portal from 'features/payment/Portal'
import Success from 'features/payment/Success'
import GetProductPrices from 'features/products/GetProductPrices'
import Profile from 'features/profile/Profile'
import Report from 'features/report/Report'
import useSession from 'features/session/useSession'
import Settings from 'features/settings/Settings'
import ForgotPassword from 'features/signin/ForgotPassword'
import SignIn from 'features/signin/SignIn'
import OnBoardingPage from 'features/signup/OnBoardingPage'
import Dashboard from 'features/trips/Dashboard'
import React from 'react'
import { Redirect, Route, Switch } from 'react-router'
import routes from './routes'

const ProtectedRoute = ({ session, ...props }) => {
  if (session.isLoading || session.isIdle) {
    return <Progress />
  }

  if (session.data) {
    return <Route {...props} />
  }

  return <Redirect to={routes.SIGN_IN} />
}

export default () => {
  const session = useSession()

  return (
    <Switch>
      <ProtectedRoute session={session} exact path="/" component={Index} />
      <ProtectedRoute
        exact
        path={routes.EXPENSE_REPORT_YEARMONTH}
        session={session}
        component={Report}
      />
      <ProtectedRoute
        exact
        path={routes.EXPENSE_REPORT}
        session={session}
        component={Report}
      />
      <ProtectedRoute
        exact
        path={routes.BIKES}
        session={session}
        component={Bikes}
      />
      <ProtectedRoute
        exact
        path={routes.BIKE}
        session={session}
        component={Bike}
      />
      <ProtectedRoute
        exact
        path={routes.TRIPS}
        session={session}
        component={Dashboard}
      />
      <ProtectedRoute
        exact
        path={routes.DOCUMENT_ID}
        session={session}
        component={DocumentRedirection}
      />
      <ProtectedRoute
        exact
        path={routes.BIKE_DOCUMENT}
        session={session}
        component={DocumentRedirection}
      />
      <ProtectedRoute
        exact
        path={routes.BIKE_DOCUMENT_ID}
        session={session}
        component={DocumentRedirection}
      />
      <ProtectedRoute
        exact
        path={routes.PROFILE}
        session={session}
        component={Profile}
      />
      <ProtectedRoute
        exact
        path={routes.SETTINGS}
        session={session}
        component={Settings}
      />
      <Route
        exact
        path={routes.CHECKOUT}
        session={session}
        component={Checkout}
      />

      <Route
        exact
        path={routes.CHECKOUT_INSURANCE}
        session={session}
        component={Checkout}
      />

      <Route
        exact
        path={routes.CHECKOUT_SUCCESS}
        session={session}
        component={Success}
      />

      <Route
        exact
        path={routes.CUSTOMER_PORTAL}
        component={Portal}
        session={session}
      />

      <Route
        exact
        path={routes.SIGN_IN_REDIRECTION}
        component={SignInRedirection}
      />

      <Route
        exact
        path={routes.GET_INSURANCE_QUOTE}
        component={GetInsuranceQuote}
      />

      <Route
        exact
        path={routes.GET_PRODUCT_PRICES}
        component={GetProductPrices}
      />

      <Route exact path={routes.FORGOT_PASSWORD} component={ForgotPassword} />

      <Route exact path={routes.SIGN_IN} component={SignIn} />
      <Route exact path={routes.SIGN_UP} component={OnBoardingPage} />
      <Route exact path="/redirect" component={RedirectPage} />

      <Route exact path={routes.NotFound} component={NotFoundPage} />
      <Route component={NotFoundPage} />
    </Switch>
  )
}

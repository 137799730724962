import { Button, Link, Stack, TextField } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink } from 'react-router-dom'

const SignInForm = ({
  onSignInClick,
  logo = 'logo.png',
  signUpUrl,
  forgotPasswordUrl,
  loading,
}) => {
  const { t } = useTranslation()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')

  const handleSubmit = (event) => {
    if (event) {
      event.preventDefault()
    }
    onSignInClick({ email, password })
  }

  const isInvalid = password === '' || email === ''

  return (
    <Stack
      justifyContent="center"
      alignItems="stretch"
      spacing={2}
      component="form"
      onSubmit={handleSubmit}
    >
      <Box alignSelf="center" mb={4} p={4}>
        <img src={logo} alt={t('Sweel')} style={{ width: 125 }} />
      </Box>
      <Stack spacing={0}>
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t('Email address')}
          autoComplete="email"
          autoFocus
          onChange={(e) => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          required
          fullWidth
          label={t('Password')}
          type="password"
          autoComplete="current-password"
          onChange={(e) => setPassword(e.target.value)}
        />
      </Stack>
      <Button
        fullWidth
        disabled={isInvalid || loading}
        type="submit"
        variant="contained"
      >
        {t('Sign in with Email')}
      </Button>
      <Stack direction="row" justifyContent="space-between">
        <Link component={RouterLink} to={forgotPasswordUrl} variant="body2">
          {t('Forgot password?')}
        </Link>
        <Link href={signUpUrl} variant="body2" target="_blank" rel="noopener">
          {t('No account? Register')}
        </Link>
      </Stack>
    </Stack>
  )
}

export default SignInForm

import { Close } from '@mui/icons-material'
import {
  Button,
  Checkbox,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Grid,
  IconButton,
  Stack,
} from '@mui/material'
import { getMe, getRetailers, insertRetailer } from 'api/firebase'
import FormTextField from 'components/FormTextField'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'

export default ({ open, onClose, onSave, maintenance }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const { data: user } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const { data: retailers, isLoading } = useQuery(
    'retailers',
    () => getRetailers(user.countryId),
    {
      initialData: [],
      onError: (e) => showError(snackbar, e, t),
      enabled: !!user,
    }
  )

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    getValues,
  } = useForm({ mode: 'all' }, { defaultValue: maintenance })

  const isFormValid = isValid && isDirty

  const onSubmit = async (data) => {
    onClose()
    const { retailer, emailNotification } = data
    const alreadyExistingRetailer = retailers.find(
      (r) => r.name === retailer.name
    )

    if (!alreadyExistingRetailer) {
      const id = await insertRetailer(retailer)
      onSave({
        ...maintenance,
        retailer: { ...retailer, id, approved: false },
        emailNotification,
      })
    } else {
      onSave({
        ...maintenance,
        retailer: {
          ...retailer,
          id: alreadyExistingRetailer.id,
          approved: true,
        },
        emailNotification,
      })
    }
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="sm">
      <DialogTitle>
        {maintenance ? t('Edit maintenance') : t('Add maintenance')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t(
            'Get a notification when it is time for your vehicle to do a maintenance checkup at your retailer'
          )}
        </DialogContentText>
        <Grid
          container
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={1}
        >
          <Grid item>
            <Grid container spacing={2}>
              <Grid item xs={12} mt={2}>
                <Controller
                  name="retailer"
                  control={control}
                  defaultValue={get(maintenance, 'retailer', {})}
                  rules={{
                    required: t('Required'),
                  }}
                  render={({
                    field: { onChange, value },
                    fieldState: { error },
                  }) => (
                    <VirtualizedAutocomplete
                      options={retailers}
                      value={value}
                      fullWidth
                      freeSolo
                      getValue={(option) => option.name || ''}
                      label={t('Retailer name')}
                      required
                      onChange={(v) => {
                        if (typeof v === 'string') {
                          if (!retailers.find((r) => r.name === v)) {
                            onChange({
                              id: undefined,
                              address: {
                                street: '',
                                number: '',
                                box: '',
                                zip: '',
                                city: '',
                                country: '',
                              },
                              phone: '',
                              email: '',
                              name: v || '',
                            })
                          } else {
                            onChange({
                              ...getValues('retailer'),
                              name: v || '',
                            })
                          }
                        } else {
                          onChange(v)
                          setValue(
                            'retailer.address.street',
                            get(v, 'address.street', '')
                          )
                          setValue(
                            'retailer.address.number',
                            get(v, 'address.number', '')
                          )
                          setValue(
                            'retailer.address.box',
                            get(v, 'address.box', '')
                          )
                          setValue(
                            'retailer.address.zip',
                            get(v, 'address.zip', '')
                          )
                          setValue(
                            'retailer.address.city',
                            get(v, 'address.city', '')
                          )
                          setValue(
                            'retailer.address.country',
                            get(v, 'address.country', '')
                          )
                          setValue('retailer.email', get(v, 'email', ''))
                          setValue('retailer.phone', get(v, 'phone', ''))
                        }
                      }}
                      helperText={error ? error.message : null}
                      error={!!error}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={8}>
                <FormTextField
                  name="retailer.address.street"
                  control={control}
                  label={t('Street')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <FormTextField
                  name="retailer.address.number"
                  control={control}
                  label={t('Number')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={2}>
                <FormTextField
                  name="retailer.address.box"
                  control={control}
                  label={t('Box')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={4}>
                <FormTextField
                  name="retailer.address.zip"
                  control={control}
                  label={t('Zip')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={8}>
                <FormTextField
                  name="retailer.address.city"
                  control={control}
                  label={t('City')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="retailer.address.country"
                  control={control}
                  label={t('Country')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="retailer.email"
                  control={control}
                  label={t('Email')}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12}>
                <FormTextField
                  name="retailer.phone"
                  control={control}
                  label={t('Phone')}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item>
            <Controller
              name="emailNotification"
              control={control}
              defaultValue={get(maintenance, 'emailNotification', false)}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  required
                  control={<Checkbox checked={value} onChange={onChange} />}
                  label={t('Email notification')}
                />
              )}
            />
          </Grid>

          <Grid item>
            <Stack spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading || !isFormValid}
              >
                {t('Save')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

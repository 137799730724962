import { Button } from '@mui/material'
import { grey } from '@mui/material/colors'
import { styled } from '@mui/material/styles'
import React from 'react'

const AppleButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#fff'),
  backgroundColor: '#fff',
  '&:hover': {
    backgroundColor: grey[200],
  },
}))

export default ({ onClick, text }) => {
  return (
    <AppleButton
      variant="contained"
      onClick={onClick}
      startIcon={
        <img
          src="/apple.svg"
          style={{
            width: '12px',
            // safari stretch bug
            height: 'intrinsic',
            marginRight: '4px',
            marginBottom: '4px',
          }}
          alt={text}
        />
      }
    >
      {text}
    </AppleButton>
  )
}

import { AssistantDirection } from '@mui/icons-material'
import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import { firebaseDateToJs } from 'api/firebase'
import BikeCardLine from 'features/bike/BikeCardLine'
import React from 'react'
import { useTranslation } from 'react-i18next'
import metersToFeet from 'utils/metersToFeet'
import metersToMiles from 'utils/metersToMiles'

export default ({ itinerary, user }) => {
  const { t } = useTranslation()
  const isSmallScren = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const distanceWithUnit = (() => {
    switch (user.distanceUnit) {
      case 'miles':
        return `${Math.floor(metersToMiles(itinerary.summary.distance))} ${t(
          'miles'
        )}`
      default:
        return `${Math.floor(itinerary.summary.distance / 1000)} ${t('km')}`
    }
  })()

  const elevationWithUnit = (() => {
    switch (user.distanceUnit) {
      case 'miles':
        return `${Math.floor(
          metersToFeet(itinerary.summary.elevationGain)
        )} ${t('ft')}`
      default:
        return `${Math.floor(itinerary.summary.elevationGain)}  ${'meters'}`
    }
  })()

  return (
    <Card>
      <CardHeader
        avatar={<AssistantDirection />}
        title={<Typography variant="h6">{itinerary.name}</Typography>}
      />
      <CardContent
        sx={{
          padding: 1,
          '&:last-child': {
            paddingBottom: 1,
          },
        }}
      >
        <Grid container>
          <Grid item container direction="column" spacing={1}>
            <Grid item>
              <BikeCardLine label={t('Distance')} value={distanceWithUnit} />
            </Grid>
            <Grid item>
              <BikeCardLine
                label={t('Elevation gain')}
                value={elevationWithUnit}
              />
            </Grid>
            <Grid item>
              <BikeCardLine
                label={t('Date')}
                value={firebaseDateToJs(
                  itinerary.summary.startTime
                ).toLocaleString()}
              />
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          item
          container
          direction={isSmallScren ? 'column' : 'row'}
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={4}
        />
      </CardActions>
    </Card>
  )
}

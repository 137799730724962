import { UploadFile } from '@mui/icons-material'
import { Button, Container, Grid } from '@mui/material'
import { getMe, getTrips } from 'api/firebase'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery, useQueryClient } from 'react-query'
import { showError } from 'utils/notifier'
import Itinerary from './Itinerary'

export default () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const { data: trips, isLoading: itinerariesIsLoading } = useQuery(
    'trips',
    getTrips,
    {
      placeholderData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { data: user, isLoading: userIsLoading } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  console.log(trips)

  return (
    <NavigationMenu title={t('Trips')} progress={itinerariesIsLoading}>
      <Container maxWidth="sm">
        {user && (
          <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="stretch"
            mt={4}
            mb={4}
            spacing={4}
          >
            <Grid item>
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="stretch"
                spacing={2}
              >
                {trips.map((trip) => (
                  <Grid item key={trip.id}>
                    <Itinerary itinerary={trip} user={user} />
                  </Grid>
                ))}
              </Grid>
            </Grid>
          </Grid>
        )}
      </Container>
    </NavigationMenu>
  )
}

import { Description, Edit, Upload } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from '@mui/material'
import documentTypes from 'api/documentTypes'
import { format, parse } from 'date-fns'
import get from 'lodash.get'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import BikeCardLine from './BikeCardLine'

export default ({ vehicle, user, onEdit, onDocumentSet }) => {
  const { t } = useTranslation()

  const isSmallScren = useMediaQuery((theme) => theme.breakpoints.down('md'))

  const [anchorEl, setAnchorEl] = useState(null)
  const menuDocumentOpen = Boolean(anchorEl)

  const handleDocumentSet = async (documentType, { target }) => {
    setAnchorEl(null)
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onloadend = (e) => {
      onDocumentSet({
        data: e.target.result,
        extension: target.files[0].name.split('.').pop().toLowerCase(),
        fileName: documentType.fileName,
        property: documentType.property,
      })
    }
  }

  return (
    <Card>
      <CardHeader
        avatar={<Description />}
        title={<Typography variant="h6">{t('Info')}</Typography>}
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item>
                <BikeCardLine label={t('Name')} value={vehicle.name} />
              </Grid>
              <Grid item>
                <BikeCardLine label={t('Brand')} value={vehicle.make} />
              </Grid>
              <Grid item>
                <BikeCardLine label={t('Model')} value={vehicle.model} />
              </Grid>
              <Grid item>
                <BikeCardLine label={t('Type')} value={t(vehicle.type)} />
              </Grid>
              <Grid item>
                <BikeCardLine
                  label={t('Electric')}
                  value={vehicle.isElectric}
                />
              </Grid>
              <Grid item>
                <BikeCardLine label={t('Color')} value={vehicle.color} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container direction="column" spacing={1}>
              <Grid item hidden={!vehicle.isElectric}>
                <BikeCardLine
                  label={t('Motor')}
                  value={vehicle.engineManufacturer}
                />
              </Grid>
              <Grid item hidden={!vehicle.isElectric}>
                <BikeCardLine
                  label={t('Battery capacity')}
                  value={
                    vehicle.batteryCapacity
                      ? `${vehicle.batteryCapacity} Wh`
                      : undefined
                  }
                />
              </Grid>
              <Grid item hidden={!vehicle.isElectric}>
                <BikeCardLine
                  label={t('Engine power')}
                  value={
                    vehicle.enginePower ? `${vehicle.enginePower} W` : undefined
                  }
                />
              </Grid>
              <Grid item>
                <BikeCardLine label={t('Serial')} value={vehicle.serial} />
              </Grid>
              <Grid item>
                <BikeCardLine
                  label={t('Pedelec')}
                  value={vehicle.isSpeedPedelec}
                />
              </Grid>
              <Grid item hidden={!vehicle.isSpeedPedelec}>
                <BikeCardLine label={t('Plate')} value={vehicle.licensePlate} />
              </Grid>
              {Object.entries({
                greenCard: documentTypes.greenCard,
                registrationCertificate: documentTypes.registrationCertificate,
                invoice: documentTypes.invoice,
              }).map(
                ([key, value]) =>
                  (value.pedelecOnly === vehicle.isSpeedPedelec ||
                    !value.pedelecOnly) && (
                    <Grid item key={key}>
                      <BikeCardLine
                        label={t(key)}
                        value={get(vehicle, `${value.property}.url`, undefined)}
                      />
                    </Grid>
                  )
              )}

              {vehicle.purchaseDate && (
                <Grid item>
                  <BikeCardLine
                    label={t('Purchase date')}
                    value={format(
                      parse(vehicle.purchaseDate, 'yyyyMMdd', new Date()),
                      'dd LLL yyyy',
                      new Date()
                    )}
                  />
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <Grid
          container
          direction={isSmallScren ? 'column' : 'row'}
          justifyContent="flex-end"
          alignItems="flex-end"
          mt={4}
        >
          <Grid item>
            <Button
              color="inherit"
              startIcon={<Upload />}
              onClick={(e) => setAnchorEl(e.currentTarget)}
            >
              {t('Upload documents')}
            </Button>
          </Grid>
          <Grid item>
            <Menu
              anchorEl={anchorEl}
              open={menuDocumentOpen}
              onClose={() => setAnchorEl(null)}
            >
              {Object.entries({
                greenCard: documentTypes.greenCard,
                registrationCertificate: documentTypes.registrationCertificate,
                invoice: documentTypes.invoice,
              }).map(
                ([key, value]) =>
                  (value.pedelecOnly === vehicle.isSpeedPedelec ||
                    !value.pedelecOnly) && (
                    <MenuItem key={key} component="label">
                      {t(key)}
                      <input
                        type="file"
                        hidden
                        onChange={(e) => handleDocumentSet(value, e)}
                      />
                    </MenuItem>
                  )
              )}
            </Menu>
          </Grid>
          <Grid item>
            <Button color="inherit" startIcon={<Edit />} onClick={onEdit}>
              {t('Edit')}
            </Button>
          </Grid>
        </Grid>
      </CardActions>
    </Card>
  )
}

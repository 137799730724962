import { Container, Grid } from '@mui/material'
import React from 'react'
import { useHistory } from 'react-router'
import GetQuote from './GetQuote'

export default () => {
  const history = useHistory()
  return (
    <Container maxWidth="sm">
      <Grid
        container
        mt={4}
        mb={4}
        minHeight="90vh"
        justifyContent="center"
        alignItems="center"
      >
        <Grid item>
          <GetQuote onGetInsurance={() => history.push('/sign-up')} />
        </Grid>
      </Grid>
    </Container>
  )
}

import { ElectricBike, PedalBike } from '@mui/icons-material'
import {
  Avatar,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
} from '@mui/material'
import BikeCardLine from 'features/bike/BikeCardLine'
import get from 'lodash.get'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'

export default ({ vehicle, user, onClick, onDelete }) => {
  const { t } = useTranslation()

  const { data: picture } = useQuery(
    ['vehicles', vehicle.id, 'picture'],
    () => vehicle.picture.url,
    {
      enabled: Boolean(get(vehicle, 'picture.url', undefined)),
    }
  )

  return (
    <Card>
      <CardActionArea onClick={() => onClick(vehicle)}>
        <Grid container justifyContent="center" alignItems="stretch">
          <Grid
            item
            container
            xs={12}
            sm={6}
            md={4}
            sx={{
              minHeight: '250px',
            }}
            justifyContent="center"
            alignItems="center"
          >
            {picture ? (
              <Avatar
                sx={{
                  height: '200px',
                  width: '200px',
                }}
              >
                <CardMedia
                  component="img"
                  image={picture}
                  alt={vehicle.name}
                  sx={{
                    height: '200px',
                  }}
                />
              </Avatar>
            ) : vehicle.isElectric ? (
              <ElectricBike
                color="disabled"
                sx={{
                  fontSize: 140,
                }}
              />
            ) : (
              <PedalBike
                color="disabled"
                sx={{
                  fontSize: 140,
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} sm={6} md={8} alignSelf="center">
            <CardContent>
              <Grid container spacing={1}>
                <Grid item xs={12} md={6}>
                  <Grid item container direction="column" spacing={1}>
                    <Grid item>
                      <BikeCardLine label={t('Name')} value={vehicle.name} />
                    </Grid>
                    <Grid item>
                      <BikeCardLine label={t('Brand')} value={vehicle.make} />
                    </Grid>
                    <Grid item>
                      <BikeCardLine label={t('Model')} value={vehicle.model} />
                    </Grid>
                    <Grid item>
                      <BikeCardLine label={t('Type')} value={t(vehicle.type)} />
                    </Grid>
                  </Grid>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Grid item container direction="column" spacing={1}>
                    <Grid item>
                      <BikeCardLine
                        label={t('Insurance')}
                        value={!!vehicle.thirdPartyInsurance}
                      />
                    </Grid>
                    <Grid item>
                      <BikeCardLine
                        label={t('Locks')}
                        value={vehicle.locks ? vehicle.locks.length : 0}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}

import Autocomplete, { autocompleteClasses } from '@mui/material/Autocomplete'
import ListSubheader from '@mui/material/ListSubheader'
import Popper from '@mui/material/Popper'
import { styled, useTheme } from '@mui/material/styles'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import useMediaQuery from '@mui/material/useMediaQuery'
import * as React from 'react'
import { VariableSizeList } from 'react-window'

const LISTBOX_PADDING = 8 // px

const renderRow = (props) => {
  const { data, index, style } = props
  const dataSet = data[index]
  const inlineStyle = {
    ...style,
    top: style.top + LISTBOX_PADDING,
  }

  if (dataSet.group) {
    return (
      <ListSubheader key={dataSet.key} component="div" style={inlineStyle}>
        {dataSet.group}
      </ListSubheader>
    )
  }

  return (
    <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
      {dataSet[1]}
    </Typography>
  )
}

const OuterElementContext = React.createContext({})

const OuterElementType = React.forwardRef((props, ref) => {
  const outerProps = React.useContext(OuterElementContext)
  return <div ref={ref} {...props} {...outerProps} />
})

const useResetCache = (data) => {
  const ref = React.useRef(null)
  React.useEffect(() => {
    if (ref.current != null) {
      ref.current.resetAfterIndex(0, true)
    }
  }, [data])
  return ref
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(
  props,
  ref
) {
  const { children, ...other } = props
  const itemData = []
  children.forEach((item) => {
    itemData.push(item)
    itemData.push(...(item.children || []))
  })

  const theme = useTheme()
  const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
    noSsr: true,
  })

  const itemCount = itemData.length
  const itemSize = smUp ? 36 : 48

  const getChildSize = (child) => {
    if (child.group) {
      return 48
    }

    return itemSize
  }

  const getHeight = () => {
    if (itemCount > 8) {
      return 8 * itemSize
    }
    return itemData.map(getChildSize).reduce((a, b) => a + b, 0)
  }

  const gridRef = useResetCache(itemCount)

  return (
    <div ref={ref}>
      <OuterElementContext.Provider value={other}>
        <VariableSizeList
          itemData={itemData}
          height={getHeight() + 2 * LISTBOX_PADDING}
          width="100%"
          ref={gridRef}
          outerElementType={OuterElementType}
          innerElementType="ul"
          itemSize={(index) => getChildSize(itemData[index])}
          overscanCount={5}
          itemCount={itemCount}
        >
          {renderRow}
        </VariableSizeList>
      </OuterElementContext.Provider>
    </div>
  )
})

const StyledPopper = styled(Popper)({
  [`& .${autocompleteClasses.listbox}`]: {
    boxSizing: 'border-box',
    '& ul': {
      padding: 0,
      margin: 0,
    },
  },
})

const VirtualizedAutocomplete = ({
  options,
  label,
  inputValue,
  value,
  onChange,
  getValue = (v) => v,
  freeSolo = false,
  ...textFieldsProps
}) => {
  return (
    <Autocomplete
      disableListWrap
      autoComplete={false}
      PopperComponent={StyledPopper}
      ListboxComponent={ListboxComponent}
      options={options}
      inputValue={inputValue}
      value={value}
      autoSelect
      getOptionLabel={(option) => getValue(option)}
      groupBy={(option) => getValue(option)[0].toUpperCase()}
      renderInput={(params) => (
        <TextField {...params} label={label} {...textFieldsProps} />
      )}
      isOptionEqualToValue={(option, v) => {
        return getValue(option) === getValue(v)
      }}
      renderOption={(props, option) => [props, getValue(option)]}
      renderGroup={(params) => params}
      onChange={(e, v) => {
        onChange(v)
      }}
      // enable free solo and textfield value change if the options is empty
      onInputChange={(e, v) => {
        return !options[0] ? onChange(v) : null
      }}
      freeSolo={freeSolo || !options[0]}
    />
  )
}

export default VirtualizedAutocomplete

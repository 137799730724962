import { Container, Typography } from '@mui/material'
import { getCustomerPortalUrl, signInWithIdToken } from 'api/firebase'
import Progress from 'components/Progress'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

const Portal = () => {
  const location = useLocation()
  const history = useHistory()

  const { idToken, returnUrl } = queryString.parse(location.search, {
    parseNumbers: true,
  })

  const [error, setError] = useState()

  useEffect(() => {
    const startPortal = async () => {
      try {
        if (!idToken) {
          throw Error('No idToken specified')
        }

        await signInWithIdToken(idToken)
        const url = await getCustomerPortalUrl(
          returnUrl || 'https://sweel.com/'
        )
        window.location.replace(url)
      } catch (e) {
        console.error(e)
        setError(e)
      }
    }

    startPortal()
  }, [idToken, history, returnUrl])

  return (
    <Container
      sx={{ flexGrow: 1, marginTop: 8, marginBottom: 8 }}
      maxWidth="sm"
    >
      {error ? (
        <div>
          <Typography sx={{ color: 'error' }}>{error.message}</Typography>
        </div>
      ) : (
        <Progress />
      )}
    </Container>
  )
}

export default Portal

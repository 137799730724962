import { Visibility, VisibilityOff } from '@mui/icons-material'
import {
  FormControl,
  FormHelperText,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
} from '@mui/material'
import React, { useState } from 'react'
import { Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default ({
  control,
  name,
  defaultValue = '',
  rules,
  label,
  ...inputProps
}) => {
  const { t } = useTranslation()
  const [showPassword, setShowPassword] = useState(false)

  return (
    <Controller
      name={name}
      control={control}
      defaultValue={defaultValue}
      rules={{
        required: inputProps.required ? t('Required') : false,
        ...rules,
      }}
      render={({ field, fieldState: { error } }) => (
        <FormControl variant="outlined" {...inputProps}>
          <InputLabel htmlFor="password" error={!!error}>
            {label}
          </InputLabel>
          <OutlinedInput
            id="password"
            {...field}
            label={label}
            type={showPassword ? 'text' : 'password'}
            error={!!error}
            autoComplete="new-password"
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowPassword(!showPassword)}
                  onMouseDown={(e) => e.preventDefault()}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
          />
          {error && <FormHelperText error>{error.message}</FormHelperText>}
        </FormControl>
      )}
    />
  )
}

import { Edit, Lock } from '@mui/icons-material'
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Grid,
  Typography,
  useMediaQuery,
} from '@mui/material'
import get from 'lodash.get'
import React from 'react'
import { useTranslation } from 'react-i18next'
import BikeCardLine from './BikeCardLine'

export default ({ vehicle, onEdit, onAdd }) => {
  const { t } = useTranslation()

  const isSmallScren = useMediaQuery((theme) => theme.breakpoints.down('md'))

  return (
    <Card>
      <CardHeader
        avatar={<Lock />}
        title={<Typography variant="h6">{t('Locks')}</Typography>}
      />
      <CardContent>
        <Grid container spacing={4}>
          {vehicle.locks && vehicle.locks.length > 0 ? (
            vehicle.locks.map((lock, index) => (
              <Grid
                item
                container
                direction="column"
                spacing={1}
                xs={12}
                md={6}
                key={index}
              >
                <Grid item>
                  <BikeCardLine label={t('Brand')} value={lock.brand} />
                </Grid>
                <Grid item>
                  <BikeCardLine label={t('Type')} value={t(lock.type)} />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Certification')}
                    value={t(lock.certification)}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Protection level')}
                    value={lock.protectionLevel}
                  />
                </Grid>
                <Grid item>
                  <BikeCardLine
                    label={t('Lock invoice')}
                    value={get(lock, 'invoice.url', undefined)}
                  />
                </Grid>
                <Grid item alignSelf="center">
                  <Button
                    color="inherit"
                    startIcon={<Edit />}
                    onClick={() => onEdit(index)}
                  >
                    {t('Edit')}
                  </Button>
                </Grid>
              </Grid>
            ))
          ) : (
            <Grid item container direction="column" spacing={1} xs={12} md={6}>
              <Grid item>
                <BikeCardLine label={t('Locks')} value={undefined} />
              </Grid>
            </Grid>
          )}
        </Grid>
      </CardContent>
      <CardActions>
        {!(vehicle.locks && vehicle.locks.length >= 4) && (
          <Grid
            container
            direction={isSmallScren ? 'column' : 'row'}
            justifyContent="flex-end"
            alignItems="flex-end"
            mt={4}
          >
            <Grid item>
              <Button
                color="inherit"
                startIcon={<Edit />}
                onClick={() => onAdd(vehicle.locks ? vehicle.locks.length : 0)}
              >
                {t('Add lock')}
              </Button>
            </Grid>
          </Grid>
        )}
      </CardActions>
    </Card>
  )
}

import PhotoCameraIcon from '@mui/icons-material/PhotoCamera'
import {
  Avatar,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import documentTypes from 'api/documentTypes'
import { getVehicleBrands, getVehicleEngineBrands } from 'api/firebase'
import FormTextField from 'components/FormTextField'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import config from 'config'
import { format, parse } from 'date-fns'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'

export default ({ onSaveVehicle, isLoading, vehicle }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    watch,
  } = useForm({ mode: 'all' })

  const [picture, setPicture] = useState()

  const { data: brands } = useQuery('brands', getVehicleBrands, {
    initialData: [],
    onError: (e) => showError(snackbar, e, t),
  })

  const { data: engineBrands } = useQuery(
    'engineBrands',
    getVehicleEngineBrands,
    {
      initialData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const handlePictureSet = async ({ target }) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onloadend = (e) => {
      setPicture({
        ...documentTypes.picture,
        data: e.target.result,
        extension: target.files[0].name.split('.').pop().toLowerCase(),
      })
    }
  }

  const isFormValid = isValid && (isDirty || picture)

  const onSubmit = async ({
    name,
    make,
    model,
    type,
    isElectric = false,
    isSpeedPedelec = false,
    licensePlate,
    serial,
    color,
    engineManufacturer,
    purchaseDate,
    initialDistance,
    enginePower,
    batteryCapacity,
  }) => {
    onSaveVehicle({
      id: vehicle ? vehicle.id : undefined,
      vehicle: {
        name,
        make,
        model,
        type,
        isElectric,
        isSpeedPedelec,
        licensePlate,
        serial,
        color,
        engineManufacturer,
        purchaseDate: purchaseDate
          ? format(purchaseDate, 'yyyyMMdd')
          : undefined,
        initialDistance: initialDistance ? initialDistance * 1000 : undefined,
        enginePower: enginePower ? parseInt(enginePower, 10) : undefined,
        batteryCapacity: batteryCapacity
          ? parseInt(batteryCapacity, 10)
          : undefined,
      },
      files: [picture],
    })
  }

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      mt={4}
    >
      <Grid item alignSelf="center">
        <label htmlFor="picture-upload">
          <input
            accept="image/*"
            id="picture-upload"
            type="file"
            style={{ display: 'none' }}
            onChange={handlePictureSet}
          />
          <Avatar sx={{ width: 96, height: 96 }} src={picture && picture.data}>
            <IconButton component="span">
              <PhotoCameraIcon />
            </IconButton>
          </Avatar>
        </label>
      </Grid>
      <Grid item container spacing={2}>
        <Grid item xs={12}>
          <FormTextField
            name="name"
            control={control}
            defaultValue={get(vehicle, 'name', 'My First Bike')}
            required
            label={t('Vehicle name')}
            fullWidth
            helperText={t('A name to identify the vehicle')}
          />
        </Grid>
        <Grid item xs={12}>
          <Controller
            name="make"
            control={control}
            defaultValue={get(vehicle, 'make', '')}
            rules={{
              required: t('Required'),
            }}
            render={({ field: { value, onChange }, fieldState: { error } }) => (
              <VirtualizedAutocomplete
                options={brands}
                label={t('Brand')}
                required
                value={value}
                onChange={onChange}
                helperText={error ? error.message : null}
                error={!!error}
              />
            )}
          />
        </Grid>
        {vehicle && (
          <Grid item xs={12}>
            <FormTextField
              name="model"
              control={control}
              defaultValue={get(vehicle, 'model', '')}
              label={t('Model')}
              fullWidth
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              defaultValue={get(vehicle, 'type', '')}
              rules={{
                required: t('Required'),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required error={!!error}>
                  <InputLabel>{t('Type')}</InputLabel>
                  <Select
                    value={value}
                    label={`${t('Type')} *`}
                    onChange={onChange}
                  >
                    {config.bikeType.map((type) => (
                      <MenuItem key={type} value={type}>
                        {t(type)}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && (
                    <FormHelperText error={!!error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={6}>
            <Controller
              name="isElectric"
              control={control}
              defaultValue={get(vehicle, 'isElectric', false)}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={onChange} />}
                  label={t('Electric')}
                />
              )}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={6} hidden={!watch('isElectric')}>
            <Controller
              name="isSpeedPedelec"
              control={control}
              defaultValue={get(vehicle, 'isSpeedPedelec', false)}
              render={({ field: { value, onChange } }) => (
                <FormControlLabel
                  control={<Checkbox checked={value} onChange={onChange} />}
                  label={t('Pedelec')}
                />
              )}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid
            item
            xs={12}
            hidden={!watch('isElectric') || !watch('isSpeedPedelec')}
          >
            <FormTextField
              name="licensePlate"
              control={control}
              defaultValue={get(vehicle, 'licensePlate', '')}
              label={t('License plate')}
              fullWidth
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={12} hidden={!watch('isElectric')}>
            <Controller
              name="engineManufacturer"
              control={control}
              defaultValue={get(vehicle, 'engineManufacturer', '')}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <VirtualizedAutocomplete
                  options={engineBrands}
                  label={t('Engine manufacturer')}
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : null}
                  error={!!error}
                />
              )}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={6} hidden={!watch('isElectric')}>
            <FormTextField
              name="batteryCapacity"
              control={control}
              label={t(`Battery capacity`)}
              fullWidth
              defaultValue={get(vehicle, 'batteryCapacity')}
              rules={{
                pattern: {
                  value: /^\d*\.?|,?\d+$/,
                  message: t('Invalid value'),
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">Wh</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={6} hidden={!watch('isElectric')}>
            <FormTextField
              name="enginePower"
              control={control}
              label={t(`Engine power`)}
              fullWidth
              defaultValue={get(vehicle, 'enginePower')}
              rules={{
                pattern: {
                  value: /^\d*\.?|,?\d+$/,
                  message: t('Invalid value'),
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">W</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={12}>
            <FormTextField
              name="serial"
              control={control}
              defaultValue={get(vehicle, 'serial', '')}
              label={t('Serial')}
              fullWidth
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={12}>
            <FormTextField
              name="color"
              control={control}
              defaultValue={get(vehicle, 'color', '')}
              label={t('Main color')}
              fullWidth
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={12} md={6}>
            <Controller
              name="purchaseDate"
              control={control}
              defaultValue={parse(
                get(vehicle, 'purchaseDate', format(new Date(), 'yyyyMMdd')),
                'yyyyMMdd',
                new Date()
              )}
              rules={{
                required: t('Required'),
              }}
              render={({ field: { value, onChange } }) => (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DatePicker
                    fullWidth
                    required
                    inputVariant="outlined"
                    label={t('Purchase date')}
                    value={value}
                    onChange={onChange}
                    renderInput={(props) => (
                      <TextField required fullWidth {...props} />
                    )}
                  />
                </LocalizationProvider>
              )}
            />
          </Grid>
        )}
        {vehicle && (
          <Grid item xs={12} md={6}>
            <FormTextField
              name="initialDistance"
              required
              control={control}
              label={t(`Mileage`)}
              defaultValue={0}
              fullWidth
              rules={{
                pattern: {
                  value: /^\d*\.?|,?\d+$/,
                  message: t('Invalid value'),
                },
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">KM</InputAdornment>
                ),
              }}
            />
          </Grid>
        )}
      </Grid>
      <Grid item>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading || !isFormValid}
        >
          {t('Save')}
        </Button>
      </Grid>
    </Grid>
  )
}

import { createTheme, ThemeProvider } from '@mui/material/styles'
import themes from 'config/themes'
import React, { createContext, useState } from 'react'

export const CustomThemeContext = createContext({
  theme: null,
  setTheme: () => null,
})

function CustomThemeProvider(props) {
  const { children } = props

  const [theme, setTheme] = useState('light')

  const generateTheme = (type) => createTheme(themes[type])

  return (
    <CustomThemeContext.Provider value={{ theme, setTheme }}>
      <CustomThemeContext.Consumer>
        {({ theme: currentTheme }) => (
          <ThemeProvider theme={generateTheme(currentTheme)}>
            {children}
          </ThemeProvider>
        )}
      </CustomThemeContext.Consumer>
    </CustomThemeContext.Provider>
  )
}

export default CustomThemeProvider

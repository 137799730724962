import { Grid } from '@mui/material'
import { getCountries } from 'api/firebase'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'

export default ({ onSubmit, user }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const [countryName, setCountryName] = useState('')

  const { data: countries, isLoading: countryIsLoading } = useQuery(
    'countries',
    getCountries,
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )

  return (
    <Grid
      container
      spacing={4}
      component="form"
      alignItems="center"
      justifyContent="center"
      orientation="column"
      mt={4}
    >
      {countries && !user && (
        <Grid item xs={6}>
          <VirtualizedAutocomplete
            label={t('Country')}
            options={countries.map((c) => c.country)}
            value={countryName}
            onChange={(newCountryName) => {
              setCountryName(newCountryName)
              onSubmit(
                null,
                countries.find((c) => c.country === newCountryName)
              )
            }}
          />
        </Grid>
      )}
    </Grid>
  )
}

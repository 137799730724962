import { Container, Typography } from '@mui/material'
import {
  checkout,
  getMe,
  getSession,
  getVehicle,
  signInWithIdToken,
} from 'api/firebase'
import { redirectToCheckout } from 'api/stripe'
import Progress from 'components/Progress'
import config from 'config'
import queryString from 'query-string'
import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useLocation } from 'react-router'
import routes from 'routes/routes'
import CheckoutForm from './CheckoutInsuranceForm'

const Checkout = ({ match }) => {
  const location = useLocation()
  const { i18n } = useTranslation()
  const { productId } = match.params

  const language = i18n.languages[0]

  const {
    idToken,
    vehicleId,
    priceId,
    originalValue,
    successUrl,
    cancelUrl,
    orderNumber,
  } = queryString.parse(location.search, { parseNumbers: true })

  const [globalLoading, setGlobalLoading] = useState(true)
  const [checkoutLoading, setCheckoutLoading] = useState(false)
  const [globalError, setGlobalError] = useState()
  const [checkoutError, setCheckoutError] = useState()
  const [user, setUser] = useState()
  const [vehicle, setVehicle] = useState()
  const [isInsurance, setIsInsurance] = useState(false)

  const handleSubmit = async (form) => {
    try {
      setCheckoutLoading(true)
      setCheckoutError(null)

      const sessionId = await checkout(
        productId || config.stripe.productInsurance,
        priceId,
        successUrl || `${window.location.origin}${routes.CHECKOUT_SUCCESS}`,
        cancelUrl || `${window.location.origin}`,
        vehicleId ? vehicleId.toString() : undefined,
        form
          ? {
              ...form,
              insurance: form.insurance
                ? {
                    policyholder: {
                      ...form.insurance.policyholder,
                      email: user.email,
                      address: {
                        ...form.insurance.policyholder.address,
                        country: user.countryId,
                      },
                    },
                    vehicle: {
                      ...form.insurance.vehicle,
                      originalValue,
                    },
                  }
                : undefined,
            }
          : undefined,
        orderNumber
      )
      setCheckoutLoading(false)
      await redirectToCheckout(sessionId)
      setCheckoutError(null)
    } catch (e) {
      console.error(e)
      setCheckoutLoading(false)
      setCheckoutError(e)
    }
  }

  useEffect(() => {
    ;(async () => {
      try {
        setGlobalLoading(true)

        if (idToken) {
          await signInWithIdToken(idToken)
          setUser(await getMe())
        } else {
          const session = await getSession()
          if (session) {
            setUser(await getMe())
          }
        }

        if (vehicleId) {
          setVehicle(await getVehicle(vehicleId))
        }

        if (
          productId === config.stripe.productPremium ||
          productId === config.stripe.productUltimate
        ) {
          await handleSubmit()
        } else {
          setIsInsurance(true)
        }

        setGlobalLoading(false)
      } catch (e) {
        console.log(e)
        setGlobalLoading(false)
        setGlobalError(e)
      }
    })()
  }, [idToken, originalValue, vehicleId, language, productId])

  return (
    <Container
      sx={{
        flexGrow: 1,
        marginTop: 8,
        marginBottom: 8,
      }}
      maxWidth="sm"
    >
      {globalLoading ? (
        <Progress />
      ) : globalError ? (
        <div>
          <Typography color="text.error">{globalError.message}</Typography>
        </div>
      ) : (
        <div>
          {isInsurance && (
            <CheckoutForm
              loading={checkoutLoading}
              onSubmit={handleSubmit}
              data={{ ...user, policyholder: { ...user }, vehicle }}
              errorFields={
                checkoutError &&
                checkoutError.details &&
                Array.isArray(checkoutError.details)
                  ? checkoutError.details.flatMap((it) => it.fields)
                  : []
              }
            />
          )}
          {checkoutError && (
            <div>
              {checkoutError.details && Array.isArray(checkoutError.details) ? (
                checkoutError.details.map((it) => (
                  <Typography color="text.error" key={it.code}>
                    {it.msg || it.message}
                  </Typography>
                ))
              ) : (
                <Typography color="text.error">
                  {checkoutError.message}
                </Typography>
              )}
            </div>
          )}
        </div>
      )}
    </Container>
  )
}

export default Checkout

import {
  AppBar,
  Container,
  Divider,
  LinearProgress,
  Stack,
} from '@mui/material'
import { signInWithCrendentials } from 'api/firebase'
import AppleButton from 'components/AppleButton'
import GoogleButton from 'components/GoogleButton'
import SignInForm from 'components/SignInForm'
import { useSnackbar } from 'notistack'
import React from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from 'react-query'
import { useLocation } from 'react-router'
import { useHistory } from 'react-router-dom'
import routes from 'routes/routes'
import { showError } from 'utils/notifier'

export default () => {
  const history = useHistory()
  const snackbar = useSnackbar()
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const location = useLocation()

  const { mutate: signIn, isSignInLoading } = useMutation(
    ({ email, password }) => signInWithCrendentials(email, password),
    {
      onSuccess: () => history.replace('/'),
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const isLoading = isSignInLoading

  return (
    <>
      <AppBar
        position="static"
        sx={{
          backgroundColor: 'secondary.main',
          '& .MuiTypography-root': {
            color: 'white',
          },
        }}
      >
        {isLoading && <LinearProgress />}
      </AppBar>
      <Container maxWidth="xs">
        <Stack
          justifyContent="center"
          alignItems="stretch"
          minHeight="90vh"
          spacing={5}
        >
          <SignInForm
            logo="logo_plain.png"
            onSignInClick={signIn}
            signUpUrl={t('signupUrl')}
            forgotPasswordUrl={routes.FORGOT_PASSWORD}
            loading={isSignInLoading}
          />
          <Divider>{t('OR')}</Divider>
          <Stack direction="column" spacing={2}>
            <AppleButton
              onClick={() => {
                history.push(
                  routes.SIGN_IN_REDIRECTION.replace(':provider', 'apple')
                )
              }}
              text={t('Sign in with Apple')}
            />
            <GoogleButton
              onClick={() => {
                history.push(
                  routes.SIGN_IN_REDIRECTION.replace(':provider', 'google')
                )
              }}
              text={t('Sign in with Google')}
            />
          </Stack>
        </Stack>
      </Container>
    </>
  )
}

import { Close, UploadFile } from '@mui/icons-material'
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Stack,
} from '@mui/material'
import { getLockBrands, getLockCertifications } from 'api/firebase'
import lockTypes from 'api/lockTypes'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import get from 'lodash.get'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useQuery } from 'react-query'
import { showError } from 'utils/notifier'
import { v4 as uuidv4 } from 'uuid'

export default ({ open, onClose, onSave, onDelete, locks, index }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const [invoice, setInvoice] = useState()

  const lock = locks && locks.length > index ? locks[index] : undefined

  const { data: brands } = useQuery('lockBrands', getLockBrands, {
    initialData: [],
    onError: (e) => showError(snackbar, e, t),
  })

  const { data: certifications } = useQuery(
    'lockCertifications',
    getLockCertifications,
    {
      initialData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    reset,
  } = useForm({ mode: 'all' })

  useEffect(() => {
    setInvoice(null)
    reset()
    if (lock) {
      Object.entries(lock).forEach(([key, value]) => setValue(key, value))
    }
  }, [locks, index, setValue, reset, lock])

  const isFormValid = (isValid && isDirty) || invoice

  const handleDocumentSet = async ({ target }) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onloadend = (e) => {
      setInvoice({
        fileName: `lock-invoice-${uuidv4()}`,
        property: `locks[${index}].invoice`,
        data: e.target.result,
        extension: target.files[0].name.split('.').pop().toLowerCase(),
      })
    }
  }

  const onSubmit = async ({ brand, type, certification, protectionLevel }) => {
    onClose()
    onSave(
      {
        brand,
        type,
        certification,
        protectionLevel,
      },
      invoice
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {lock ? t('Edit vehicle lock') : t('Add vehicle lock')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Grid
          container
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={2}
        >
          <Grid item xs={12}>
            <Controller
              name="brand"
              control={control}
              defaultValue={get(lock, 'brand', '')}
              rules={{
                required: t('Required'),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <VirtualizedAutocomplete
                  options={brands}
                  label={t('Brand')}
                  required
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : null}
                  error={!!error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="type"
              control={control}
              defaultValue={get(lock, 'type', '')}
              rules={{
                required: t('Required'),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required error={!!error}>
                  <InputLabel>{t('Type')}</InputLabel>
                  <Select
                    value={value}
                    label={`${t('Type')} *`}
                    onChange={onChange}
                  >
                    {lockTypes.map((type) => (
                      <MenuItem key={type} value={type}>
                        {t(type)}
                      </MenuItem>
                    ))}
                  </Select>
                  {error && (
                    <FormHelperText error={!!error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="certification"
              control={control}
              defaultValue={get(lock, 'certification', '')}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <VirtualizedAutocomplete
                  options={certifications}
                  label={t('Certification')}
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : null}
                  error={!!error}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="protectionLevel"
              control={control}
              defaultValue={get(lock, 'protectionLevel', '')}
              rules={{
                required: t('Required'),
              }}
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <FormControl fullWidth required error={!!error}>
                  <InputLabel>{t('Level')}</InputLabel>
                  <Select
                    value={value}
                    label={`${t('Level')} *`}
                    onChange={onChange}
                  >
                    {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15].map(
                      (level) => (
                        <MenuItem key={level} value={level}>
                          {t(level)}
                        </MenuItem>
                      )
                    )}
                  </Select>
                  {error && (
                    <FormHelperText error={!!error}>
                      {error.message}
                    </FormHelperText>
                  )}
                </FormControl>
              )}
            />
          </Grid>
          <Grid item>
            <Button
              color="inherit"
              startIcon={<UploadFile />}
              component="label"
            >
              <input type="file" hidden onChange={handleDocumentSet} />
              {t('Invoice')}
            </Button>
          </Grid>
          <Grid item>
            <Stack spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={!isFormValid}
              >
                {t('Save')}
              </Button>
              <Button
                fullWidth
                hidden={!!lock}
                onClick={() => {
                  onClose()
                  onDelete()
                }}
                color="error"
              >
                {t('Delete')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Grid,
  Link,
  Stack,
} from '@mui/material'
import AppleButton from 'components/AppleButton'
import FormPassword from 'components/FormPassword'
import FormTextField from 'components/FormTextField'
import GoogleButton from 'components/GoogleButton'
import { useSnackbar } from 'notistack'
import React from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Trans, useTranslation } from 'react-i18next'
import { useHistory } from 'react-router'
import routes from 'routes/routes'

const SignUpForm = ({ onSignup, isLoading }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const history = useHistory()
  const {
    handleSubmit,
    control,
    formState: { isValid: isFormValid },
  } = useForm({ mode: 'all' })

  const onSubmit = async ({ email, password }) => {
    onSignup({
      email,
      password,
    })
  }

  return (
    <Grid
      container
      direction="column"
      spacing={4}
      component="form"
      onSubmit={handleSubmit(onSubmit)}
      mt={4}
    >
      <Grid item>
        <Stack direction="column" spacing={2}>
          <AppleButton
            onClick={() => {
              history.push(
                routes.SIGN_IN_REDIRECTION.replace(':provider', 'apple')
              )
            }}
            text={t('Sign up with Apple')}
          />
          <GoogleButton
            onClick={() => {
              history.push(
                routes.SIGN_IN_REDIRECTION.replace(':provider', 'google')
              )
            }}
            text={t('Sign up with Google')}
          />
        </Stack>
      </Grid>
      <Grid item>
        <Divider>{t('OR')}</Divider>
      </Grid>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <FormTextField
            control={control}
            name="email"
            required
            fullWidth
            label={t('Email address')}
            rules={{
              pattern: {
                value: /^\S+@\S+\.\S+$/,
                message: t('Invalid email'),
              },
            }}
          />
        </Grid>
        <Grid item>
          <FormPassword
            control={control}
            name="password"
            rules={{
              minLength: {
                value: 6,
                message: t('Password must have at least 6 characters'),
              },
            }}
            required
            fullWidth
            autoComplete="new-password"
            label={t('Password')}
          />
        </Grid>
        <Grid item>
          <Controller
            name="isLegalChecked"
            control={control}
            defaultValue={false}
            rules={{
              required: t('Required'),
            }}
            render={({ field: { value, onChange } }) => (
              <FormControlLabel
                control={<Checkbox checked={value} onChange={onChange} />}
                label={
                  <Trans i18nKey="acceptTerms">
                    I have read and agree to the{' '}
                    <Link href={t('termsUrl')} target="_blank">
                      Terms and Conditions
                    </Link>{' '}
                    and the{' '}
                    <Link href={t('disclaimerUrl')} target="_blank">
                      Disclaimer
                    </Link>
                  </Trans>
                }
              />
            )}
          />
        </Grid>
      </Grid>
      <Grid item>
        <Button
          type="submit"
          fullWidth
          variant="contained"
          disabled={isLoading || !isFormValid}
        >
          {t('Sign-Up')}
        </Button>
      </Grid>
    </Grid>
  )
}
export default SignUpForm

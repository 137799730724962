import { Container, Grid, Typography } from '@mui/material'
import React from 'react'

const NotFoundPage = () => {
  return (
    <Container>
      <Grid
        container
        style={{ minHeight: '90vh' }}
        direction="column"
        alignItems="center"
        justifyContent="center"
        spacing={4}
      >
        <Grid item>
          <Typography variant="h1">(&gt;_&lt;)</Typography>
        </Grid>
        <Grid item>
          <Typography variant="body2">404 Not Found</Typography>
        </Grid>
      </Grid>
    </Container>
  )
}

export default NotFoundPage

import { Delete } from '@mui/icons-material'
import {
  Breadcrumbs,
  Container,
  Grid,
  IconButton,
  Link,
  Typography,
} from '@mui/material'
import {
  deleteVehicle as deleteVehicleDoc,
  getListOfYearMonthWithTrips,
  getMe,
  getTracker,
  getVehicle,
  updateVehicle as updateVehicleDoc,
} from 'api/firebase'
import SaveBikeDialog from 'features/bikes/SaveBikeDialog'
import GetMobileAppDialog from 'features/common/GetMobileAppDialog'
import GetProductDialog from 'features/common/GetProductDialog'
import GetInsuranceDialog from 'features/insurance/GetInsuranceDialog'
import NavigationMenu from 'features/menu/NavigationMenu'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { useHistory } from 'react-router'
import { Link as RouterLink } from 'react-router-dom'
import routes from 'routes/routes'
import { showError, showInfoMessage } from 'utils/notifier'
import BikeInfoCard from './BikeInfoCard'
import BikeInsuranceCard from './BikeInsuranceCard'
import BikeLockCard from './BikeLockCard'
import BikeStatisticsCard from './BikeStatisticsCard'
import SaveBikeLockDialog from './SaveBikeLockDialog'
import SaveExpenseReportDialog from './SaveExpenseReportDialog'
import SaveInsuranceDialog from './SaveInsuranceDialog'
import SaveMaintenanceDialog from './SaveMaintenanceDialog'

export default ({ match }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()
  const history = useHistory()

  const { id } = match.params

  const [saveVehicleDialogOpen, setSaveVehicleDialogOpen] = useState(false)
  const [getMobileAppDialogOpen, setGetMobileAppDialogOpen] = useState(false)
  const [getProductDialogOpen, setGetProductDialogOpen] = useState(false)
  const [getInsuranceDialogOpen, setGetInsuranceDialogOpen] = useState(false)
  const [saveInsuranceDialogOpen, setSaveInsuranceDialogOpen] = useState(false)
  const [saveLockDialogOpen, setSaveLockDialogOpen] = useState(false)
  const [saveMaintenanceDialogOpen, setSaveMaintenanceDialogOpen] =
    useState(false)
  const [expenseReportDialogOpen, setExpenseReportDialogOpen] = useState(false)
  const [selectedLockIndex, setSelectedLockIndex] = useState(0)

  const { data: user, isLoading: userIsLoading } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const { data: vehicle, isLoading: vehiclesIsLoading } = useQuery(
    ['vehicles', id],
    () => getVehicle(id),
    {
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { data: tracker, isLoading: trackerIsLoading } = useQuery(
    ['vehicles', id, 'tracker'],
    () => getTracker(vehicle.trackerId),
    {
      enabled: !!(vehicle && vehicle.trackerId),
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: updateVehicle, isLoading: updateVehicleIsLoading } =
    useMutation(
      ({ vehicle: data, files }) => updateVehicleDoc(id, data, files),
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['vehicles', id])
          showInfoMessage(snackbar, t('Mobility updated'))
        },
        onError: (e) => showError(snackbar, e, t),
      }
    )

  const { mutate: deleteVehicle, isLoading: deleteVehicleIsLoading } =
    useMutation(() => deleteVehicleDoc(vehicle.id), {
      onSuccess: () => {
        queryClient.invalidateQueries('vehicles')
        history.replace('/')
        showInfoMessage(snackbar, t('Vehicle removed'))
      },
      onError: (e) => showError(snackbar, e, t),
    })

  const { data: yearMonths, isLoading: yearMonthsIsLoading } = useQuery(
    ['yearMonths'],
    () => getListOfYearMonthWithTrips(),
    {
      initialData: [],
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const isLoading =
    vehiclesIsLoading ||
    yearMonthsIsLoading ||
    updateVehicleIsLoading ||
    userIsLoading ||
    trackerIsLoading ||
    deleteVehicleIsLoading

  return (
    <NavigationMenu
      progress={isLoading}
      title={
        <Breadcrumbs>
          <Link
            underline="hover"
            color="inherit"
            variant="h6"
            component={RouterLink}
            to={routes.BIKES}
          >
            {t('Vehicles')}
          </Link>
          {vehicle && (
            <Typography color="text.primary" variant="h6">
              {vehicle.name}
            </Typography>
          )}
        </Breadcrumbs>
      }
      toolbarChildren={
        <IconButton color="inherit" onClick={deleteVehicle}>
          <Delete />
        </IconButton>
      }
    >
      <Container maxWidth="lg">
        {vehicle && user && (
          <>
            <Grid container direction="column" spacing={4} mt={4} mb={4}>
              <Grid item>
                <BikeInfoCard
                  vehicle={vehicle}
                  user={user}
                  onEdit={() => setSaveVehicleDialogOpen(true)}
                  onDocumentSet={(file) => updateVehicle({ files: [file] })}
                />
              </Grid>
              <Grid item>
                <BikeStatisticsCard vehicles={[vehicle]} user={user} />
              </Grid>
              <Grid item>
                <BikeLockCard
                  vehicle={vehicle}
                  onEdit={(index) => {
                    setSelectedLockIndex(index)
                    setSaveLockDialogOpen(true)
                  }}
                  onAdd={(index) => {
                    setSelectedLockIndex(index)
                    setSaveLockDialogOpen(true)
                  }}
                />
              </Grid>
              <Grid item>
                <BikeInsuranceCard
                  vehicle={vehicle}
                  onAdd={() => setGetInsuranceDialogOpen(true)}
                  onInsuranceEdit={() => setSaveInsuranceDialogOpen(true)}
                  onGetConnect={() => setGetProductDialogOpen(true)}
                />
              </Grid>
            </Grid>
            <SaveBikeDialog
              open={saveVehicleDialogOpen}
              onClose={() => {
                setSaveVehicleDialogOpen(false)
              }}
              onSaveVehicle={updateVehicle}
              vehicle={vehicle}
            />
            <SaveInsuranceDialog
              open={saveInsuranceDialogOpen}
              onClose={() => setSaveInsuranceDialogOpen(false)}
              insurance={vehicle.thirdPartyInsurance}
              vehicle={vehicle}
              onSave={(thirdPartyInsurance, file) =>
                updateVehicle({
                  vehicle: { thirdPartyInsurance },
                  files: [file],
                })
              }
              onAdd={() => setGetInsuranceDialogOpen(true)}
              onDelete={() =>
                updateVehicle({ vehicle: { thirdPartyInsurance: null } })
              }
            />
            <SaveMaintenanceDialog
              open={saveMaintenanceDialogOpen}
              onClose={() => setSaveMaintenanceDialogOpen(false)}
              maintenance={vehicle.maintenance}
              onSave={(maintenance) =>
                updateVehicle({
                  vehicle: { maintenance },
                })
              }
            />
            <SaveBikeLockDialog
              open={saveLockDialogOpen}
              onClose={() => setSaveLockDialogOpen(false)}
              locks={vehicle.locks}
              index={selectedLockIndex}
              onSave={(lock, file) => {
                let { locks } = vehicle
                if (!locks) {
                  locks = [lock]
                } else {
                  locks[selectedLockIndex] = lock
                }
                updateVehicle({ vehicle: { locks }, files: [file] })
              }}
              onDelete={() => {
                const { locks } = vehicle
                locks.splice(selectedLockIndex, 1)
                updateVehicle({ vehicle: { locks } })
              }}
            />
            <GetMobileAppDialog
              open={getMobileAppDialogOpen}
              onClose={() => setGetMobileAppDialogOpen(false)}
            />
            <GetProductDialog
              open={getProductDialogOpen}
              onClose={() => setGetProductDialogOpen(false)}
              vehicleId={id}
            />
            <GetInsuranceDialog
              open={getInsuranceDialogOpen}
              onClose={() => setGetInsuranceDialogOpen(false)}
              vehicle={vehicle}
              user={user}
            />
            <SaveExpenseReportDialog
              open={expenseReportDialogOpen}
              onClose={() => setExpenseReportDialogOpen(false)}
              vehicle={vehicle}
              user={user}
              onSave={(expenseReportConfiguration) => {
                updateVehicle({ vehicle: { expenseReportConfiguration } })
              }}
            />
          </>
        )}
      </Container>
    </NavigationMenu>
  )
}

import { Close } from '@mui/icons-material'
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material'
import DialogContentText from '@mui/material/DialogContentText'
import { useSnackbar } from 'notistack'
import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

export default ({ open, onClose, onSave, onDelete, lock }) => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()

  const [invoice, setInvoice] = useState()

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
  } = useForm({ mode: 'all' })

  const isFormValid = (isValid && isDirty) || invoice

  const handleDocumentSet = async ({ target }) => {
    const fileReader = new FileReader()
    fileReader.readAsDataURL(target.files[0])
    fileReader.onloadend = (e) => {
      setInvoice({
        fileName: 'lock-invoice',
        property: 'lock.invoiceURL',
        data: e.target.result,
        extension: target.files[0].name.split('.').pop().toLowerCase(),
      })
    }
  }

  const onSubmit = async ({ brand, level }) => {
    onClose()
    onSave(
      {
        brand,
        level,
      },
      invoice
    )
  }

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('No vehicle with location yet')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          {t('End a trip with your vehicle to initialize the vehicle location')}
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}

import { Close } from '@mui/icons-material'
import { Button, IconButton, Stack, Typography } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import config from 'config'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default ({ open, onClose }) => {
  const { t } = useTranslation()

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="xs">
      <DialogTitle>
        {t('Get the mobile app')}
        <IconButton
          onClick={onClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Stack
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={8}
        >
          <Typography variant="subtitle1">
            {t(
              'Download the mobile application to take full advantage of all the features'
            )}
          </Typography>

          <img
            src="/ios-apps.png"
            alt={t('iOS applications')}
            style={{ width: '250px' }}
          />
          <Stack direction="row" spacing={4}>
            <Button
              href={config.appStoreUrl}
              target="_blank"
              variant="contained"
            >
              {t('iOS')}
            </Button>
            <Button disabled variant="contained">
              {t('Android')}
            </Button>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  )
}

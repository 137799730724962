import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from '@mui/material'
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
import {
  deleteUser as deleteUserAndData,
  getCountries,
  getMe,
  passwordReset,
  signOut,
  updateMe,
} from 'api/firebase'
import FormTextField from 'components/FormTextField'
import VirtualizedAutocomplete from 'components/VirtualizedAutocomplete'
import config from 'config'
import { formatISO, parseISO } from 'date-fns'
import NavigationMenu from 'features/menu/NavigationMenu'
import get from 'lodash.get'
import pick from 'lodash.pick'
import { useSnackbar } from 'notistack'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { showError, showInfoMessage } from 'utils/notifier'

export default () => {
  const { t } = useTranslation()
  const snackbar = useSnackbar()
  const queryClient = useQueryClient()

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false)

  const {
    handleSubmit,
    control,
    formState: { isValid, isDirty },
    setValue,
    reset,
  } = useForm({ mode: 'all' })

  const isFormValid = isValid && isDirty

  const { data: countries } = useQuery('countries', getCountries, {
    placeholderData: [],
    onError: (e) => showError(snackbar, e, t),
  })

  const { data: user, isLoading: userIsLoading } = useQuery('user', getMe, {
    onError: (e) => showError(snackbar, e, t),
  })

  const { mutate: updateUser, isLoading: updateUserIsLoading } = useMutation(
    (u) => updateMe(u),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('user')
        return showInfoMessage(snackbar, t('Profile updated'))
      },
      onError: (e) => showError(snackbar, e, t),
    }
  )

  const { mutate: deleteUser, isLoading: deleteUserIsLoading } = useMutation(
    (u) => deleteUserAndData(u),
    {
      onSuccess: () => showInfoMessage(snackbar, t('User deleted')),
      onError: (e) => showError(snackbar, e, t),
    }
  )

  useEffect(() => {
    if (user && countries && countries.length > 0) {
      const country = countries.find(
        (c) => c.countryCode.toUpperCase() === user.countryId.toUpperCase()
      )

      const data = pick(user, [
        'firstName',
        'middleName',
        'lastName',
        'email',
        'phone',
        'birthDate',
        'employmentStatus',
        'address',
        'country',
        'companyName',
        'vatNumber',
      ])
      reset({
        ...data,
        country: country.country || '',
      })
    }
  }, [countries, setValue, user, reset])

  const onSubmit = async ({
    firstName,
    middleName,
    lastName,
    phone,
    birthDate,
    address: { street, number, zip, city, state },
    country,
    vatNumber,
    companyName,
    employmentStatus,
  }) => {
    updateUser({
      firstName,
      middleName,
      lastName,
      phone,
      birthDate: formatISO(new Date(birthDate), { representation: 'date' }),
      address: {
        street,
        number,
        zip,
        city,
        state,
      },
      countryId: country
        ? countries.find((c) => c.country === country).countryCode
        : undefined,
      vatNumber,
      companyName,
      employmentStatus,
    })
  }

  const isLoading = userIsLoading || updateUserIsLoading || deleteUserIsLoading

  return (
    <NavigationMenu progress={isLoading} title={t('Account')}>
      <Container maxWidth="sm">
        <Grid
          container
          direction="column"
          spacing={4}
          component="form"
          onSubmit={handleSubmit(onSubmit)}
          mt={4}
          mb={10}
        >
          <Grid item container spacing={2}>
            <Grid item xs={12} mt={4}>
              <Typography variant="subtitle1" color="text.secondary">
                {t('Company')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Controller
                name="employmentStatus"
                control={control}
                defaultValue={get(user, 'employmentStatus', '')}
                render={({
                  field: { value, onChange },
                  fieldState: { error },
                }) => (
                  <FormControl fullWidth required error={!!error}>
                    <InputLabel>{t('Employment Status')}</InputLabel>
                    <Select
                      value={value}
                      label={`${t('Employment Status')} *`}
                      onChange={onChange}
                    >
                      {config.employmentStatus.map((status) => (
                        <MenuItem key={status} value={status}>
                          {t(status)}
                        </MenuItem>
                      ))}
                    </Select>
                    {error && (
                      <FormHelperText error={!!error}>
                        {error.message}
                      </FormHelperText>
                    )}
                  </FormControl>
                )}
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="companyName"
                label={t('Company name')}
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <FormTextField
                control={control}
                name="vatNumber"
                label={t('VAT number')}
                fullWidth
              />
            </Grid>
          </Grid>
          <Grid item xs={12} mt={4}>
            <Typography variant="subtitle1" color="text.secondary">
              {t('Address')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={9}>
            <FormTextField
              control={control}
              name="address.street"
              autoComplete="street-address"
              label={t('Street')}
              fullWidth
            />
          </Grid>
          <Grid item xs={6} sm={3}>
            <FormTextField
              control={control}
              name="address.number"
              autoComplete="street-address"
              label={t('Number')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              control={control}
              name="address.zip"
              autoComplete="postal-code"
              label={t('Postal code')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormTextField
              control={control}
              name="address.city"
              autoComplete="address-level2"
              label={t('City')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="address.state"
              autoComplete="address-level3"
              label={t('State')}
              fullWidth
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="country"
              control={control}
              defaultValue=""
              render={({
                field: { value, onChange },
                fieldState: { error },
              }) => (
                <VirtualizedAutocomplete
                  label={t('Country')}
                  options={countries.map((c) => c.country)}
                  value={value}
                  onChange={onChange}
                  helperText={error ? error.message : null}
                  error={!!error}
                  fullWidth
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1" color="text.secondary">
              {t('Personal info')}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormTextField
              control={control}
              name="firstName"
              fullWidth
              label={t('First name')}
              autoComplete="given-name"
              rules={{
                maxLength: 25,
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: t('Invalid name'),
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormTextField
              control={control}
              name="middleName"
              fullWidth
              label={t('Middle name')}
              autoComplete="given-name"
              rules={{
                maxLength: 25,
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: t('Invalid name'),
                },
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <FormTextField
              control={control}
              name="lastName"
              fullWidth
              label={t('Last name')}
              autoComplete="family-name"
              rules={{
                maxLength: 25,
                pattern: {
                  value: /^([^0-9]*)$/,
                  message: t('Invalid name'),
                },
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              control={control}
              name="email"
              disabled
              required
              defaultValue={get(user, 'email', '')}
              fullWidth
              autoComplete="email"
              label={t('Email address')}
              rules={{
                pattern: {
                  value: /^\S+@\S+\.\S+$/,
                  message: t('Invalid email'),
                },
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <FormTextField
              control={control}
              name="phone"
              fullWidth
              autoComplete="tel"
              label={t('Phone number')}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="birthDate"
              control={control}
              defaultValue="2000-01-01"
              render={({ field: { value, onChange } }) => {
                return (
                  <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                      inputVariant="outlined"
                      label={t('Birthdate')}
                      value={parseISO(value)}
                      onChange={onChange}
                      renderInput={(props) => (
                        <TextField {...props} fullWidth />
                      )}
                    />
                  </LocalizationProvider>
                )
              }}
            />
          </Grid>

          <Grid item mt={4}>
            <Stack spacing={2}>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                disabled={isLoading || !isFormValid}
              >
                {t('Save')}
              </Button>
              <Button fullWidth onClick={signOut} color="inherit">
                {t('Logout')}
              </Button>
              {console.log(user)}
              {user &&
                user.providerData.find(
                  (data) => data.providerId === 'password'
                ) && (
                  <Button
                    fullWidth
                    color="inherit"
                    onClick={async () => {
                      await passwordReset(user.email)
                      showInfoMessage(
                        snackbar,
                        t('An email with reset instructions has been sent')
                      )
                    }}
                  >
                    {t('Reset password')}
                  </Button>
                )}
              <Button
                fullWidth
                onClick={() => setOpenDeleteDialog(true)}
                color="error"
              >
                {t('Delete user')}
              </Button>
            </Stack>
          </Grid>
        </Grid>
        <Dialog
          open={openDeleteDialog}
          onClose={() => setOpenDeleteDialog(false)}
        >
          <DialogTitle>{t('Delete user ?')}</DialogTitle>
          <DialogContent>
            <DialogContentText>
              {t('Are you sure you want to delete all user related data ?')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDeleteDialog(false)}
              autoFocus
              color="inherit"
            >
              {t('Cancel')}
            </Button>
            <Button
              onClick={() => {
                setOpenDeleteDialog(false)
                deleteUser()
              }}
              color="error"
            >
              {t('Delete user')}
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </NavigationMenu>
  )
}

import { Download } from '@mui/icons-material'
import { Button, Grid, Typography } from '@mui/material'
import React from 'react'
import { useTranslation } from 'react-i18next'

export default ({ label, value }) => {
  const { t } = useTranslation()

  const renderValue = () => {
    if (value === true) {
      return (
        <Typography variant="subtitle1" noWrap>
          {t('Yes')}
        </Typography>
      )
    }
    if (value === false) {
      return (
        <Typography variant="subtitle1" noWrap>
          {t('No')}
        </Typography>
      )
    }
    if (value === undefined || value === null || value === '') {
      return (
        <Typography variant="subtitle1" color="text.secondary" noWrap>
          {t('Empty')}
        </Typography>
      )
    }
    if (
      typeof value === 'string' &&
      (value.startsWith('http') || value.startsWith('/'))
    ) {
      return (
        <Button
          size="small"
          href={value}
          target="_blank"
          startIcon={<Download fontSize="small" />}
          color="inherit"
        >
          {t('Download')}
        </Button>
      )
    }
    return (
      <Typography variant="subtitle1" noWrap>
        {value}
      </Typography>
    )
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <Grid item xs={6}>
        <Typography
          variant="subtitle2"
          color="text.secondary"
          align="right"
          noWrap
        >
          {label}
        </Typography>
      </Grid>
      <Grid item xs={6}>
        {renderValue()}
      </Grid>
    </Grid>
  )
}
